// src/components/BreadcrumbComponent.js
import React from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { HiArrowLongLeft } from "react-icons/hi2";
import { HomeOutlined } from "@ant-design/icons";

const BreadcrumbComponent = ({ items }) => {
  return (
    <Breadcrumb>
      {items.map((item, index) => (
        <Breadcrumb.Item key={index}>
          {item.path ? (
            <div   className=" no-underline flex gap-2 items-center">
              <Link
                to={item.path}
                className=" no-underline flex gap-2 items-center"
              >
                <HomeOutlined size={20} />
              {item.label}
              </Link>
            </div>
          ) : (
            item.label
          )}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default BreadcrumbComponent;
