import React, { useEffect, useState } from "react";
import moment from "moment";
import { Badge, Button, Empty, Spin } from "antd";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { CalendarOutlined } from "@ant-design/icons";

const WeekCalendar = ({ loading = false, events, callback = () => {} }) => {
  const [currentWeek, setCurrentWeek] = useState(moment());

  useEffect(() => {
    const weekStart = currentWeek.clone().startOf("week").add(1, "day");

    callback(
      weekStart.format("YYYY-MM-DD"),
      weekStart.clone().add(7, "days").format("YYYY-MM-DD")
    );
  }, [currentWeek]);

  const getWeekDays = (weekStart) => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(weekStart.clone().add(i, "days"));
    }
    return days;
  };

  const weekStart = currentWeek.clone().startOf("week").add(1, "day");

  const days = getWeekDays(weekStart);

  const handlePrevWeek = () =>
    setCurrentWeek(currentWeek.clone().subtract(1, "week"));
  const handleNextWeek = () =>
    setCurrentWeek(currentWeek.clone().add(1, "week"));

  const handleToday = () => setCurrentWeek(moment());

  const getNamesForDate = (date) => {
    const event = events.find((e) => e.date === date.format("YYYY-MM-DD"));
    return event ? event.m : [];
  };

  return (
    <div className="w-full mx-auto p-4 bg-white  border-[0.5px] rounded   ">
      <div className="flex justify-between items-center mb-6">
        <span className="text-gray-600 font-bold text-[20px] ">
          {weekStart.format("MMM D")} -{" "}
          {weekStart.clone().add(6, "days").format("MMM D, YYYY")}
        </span>

        <div className="flex items-center gap-2">
          <Button
            type="primary"
            onClick={handleToday}
            // className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition duration-200 mr-2"
          >
            <CalendarOutlined />
          </Button>
          <Button
            onClick={handlePrevWeek}
            //   className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-200"
          >
            <BiChevronLeft size={20} />
          </Button>
          <Button
            onClick={handleNextWeek}
            // className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-200"
          >
            <BiChevronRight size={20} />
          </Button>
        </div>
      </div>

      <div className="grid grid-rows-7 gap-2 ">
        {days.map((day) => (
          <div key={day.format("YYYY-MM-DD")} className="flex gap-4">
            <div
              className={` p-2 flex justify-center items-center flex-col  w-[80px] rounded  transition duration-200 ${
                moment().isSame(day, "day")
                  ? "bg-blue-100 border  rounded border-blue-500"
                  : " bg-indigo-50"
              } flex-shrink-0 `}
            >
              <div className="text-sm font-medium text-gray-700">
                {day.format("ddd")}
              </div>
              <div className="text-xl font-semibold text-gray-800">
                {day.format("D")}
              </div>
            </div>
            {loading ? (
              <Spin className="flex justify-center items-center" />
            ) : (
              <>
                <div className="bg-secondary-light rounded  w-full h-full relative before:absolute before:content-[''] before:w-[4px] before:h-[100%] before:bg-stroke-light before:left-[0px] before:top-1/2 before:-translate-y-1/2 before:rounded-[50px]">
                  {getNamesForDate(day).length > 0 ? (
                    getNamesForDate(day).map((m, index) => (
                      <div
                        key={index}
                        className="text-gray-700 flex items-start gap-6  px-3 mb-2"
                      >
                        <p className="text-sm font-medium mb-2">{m?.name}</p>
                        <span className="bg-pink-100 text-pink-800 text-xs px-2.5 py-0.5 rounded-[4px] mt-1">
                          machine used {m?.machineUsed}
                        </span>
                      </div>
                    ))
                  ) : (
                    <div className="h-full  rounded bg-[repeating-linear-gradient(45deg,_#f8fafc,_#f8fafc_10px,_#e2e8f0_8px,_#e2e8f0_14px)]"></div>
                  )}
                </div>
              </>
            )}
          </div>
        ))}
      </div>

      {/* <div className="grid grid-cols-7 gap-1">
        {days.map((day) => (
          <div
            key={day.format("YYYY-MM-DD")}
            className={`p-4 border rounded text-center   transition duration-200 ${
              moment().isSame(day, "day")
                ? "bg-blue-100 border-blue-500"
                : "bg-gray-50"
            }`}
          >
            <div className="text-sm font-semibold text-gray-700">
              {day.format("ddd")}
            </div>
            <div className="text-lg font-semibold text-gray-800">
              {day.format("D")}
            </div>
            <div className="mt-2">
              {getNamesForDate(day)?.map((name, index) => (
                <div key={index} className="  text-gray-600 flex items-start  ">
                  <span className="text-[14px] text-left  "> {name}</span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default WeekCalendar;
