import React, { useEffect, useState } from "react";
import ApiCalling from "../../../../network/ApiCalling";
import Utils from "../../../../utilites/Utils";
import { Button, Checkbox, Empty, message } from "antd";
import moment from "moment";

const ChallanUpdateList = ({ onSummary, onClick, handlePrev, challanDate }) => {
  const manufactureUser = Utils.getManufactureUser();
  const [subOrderList, setSubOrderList] = useState([]);
  const [selectedDict, setSelectedDict] = useState({});

  useEffect(() => {
    getUpdatedList();
  }, []);

  const getUpdatedList = () => {
    ApiCalling.apiCallGet(`suborder-update/pending/${manufactureUser?._id}`)
      .then((res) => {
        setSubOrderList(
          res?.data?.map((order) => ({
            ...order,
            isChecked: false,
            updates: order.updates.map((update) => ({
              ...update,
              isChecked: false,
            })),
          }))
        );
      })
      .catch((err) => {
        console.log("ERR", err);
      });
  };

  const handleSubOrderCheckbox = (index, order) => {
    // console.log("order", order);
    const duplicate = selectedDict;
    const check = !order.isChecked;
    setSubOrderList((prev) =>
      prev.map((sub) =>
        sub?.subOrder?._id == order?.subOrder?._id
          ? { ...sub, isChecked: check }
          : sub
      )
    );
    for (const i of order?.updates) selectedDict[i._id] = check;
  };

  const handleUpdateCheckbox = (subOrderIndex, updateIndex, update) => {
    const check = selectedDict[update?._id] ? false : true;
    setSelectedDict((prev) => ({ ...prev, [update?._id]: check }));
  };

  const handleSubmit = () => {
    const ids = [];
    console.log(ids, selectedDict);

    for (const key in selectedDict) {
      if (selectedDict[key]) {
        ids.push(key);
      }
    }

    if (ids.length === 0 || !selectedDict) {
      message.warning("You did not select any challan, please Check!");
      return;
    }
    ApiCalling.apiCallPost(`order-chalaan/create`, {
      subOrderUpdates: ids,
      date: moment(challanDate).format("YYYY-MM-DD"),
    })
      .then((res) => {
        if (Utils.checkAPISuccessStatus(res)) {
          message.success(res?.data?.message);
          if (onSummary) {
            onSummary(res?.data?.data);
          }
          setTimeout(() => {
            onClick();
          }, 1500);
        }
      })
      .catch((err) => {
        console.log("Err", err);
      });
  };

  return (
    <>
      <div>
        <div className=" bg-secondary-light rounded py-[10px] ">
          {subOrderList.length > 0 ? (
            subOrderList?.map((order, subOrderIndex) => {
              const isChecked = order.updates.reduce((acc, itr) => {
                if (!acc) return false;
                if (selectedDict[itr?._id]) return true;
                return false;
              }, true);
              return (
                <>
                  <div className="px-2">
                    <div className="flex items-center pl-[15px] gap-[10px] h-[45px] ">
                      <Checkbox
                        checked={isChecked}
                        onChange={() =>
                          handleSubOrderCheckbox(subOrderIndex, order)
                        }
                      />

                      <p className="text-[16px] mb-0 pb-0 font-semibold">
                        {order?.subOrder?.item?.title}
                      </p>
                    </div>
                    <div className=" pl-[40px] pb-[10px]">
                      {order?.updates?.map((update, updateIndex) => {
                        return (
                          <div key={update._id}>
                            <Checkbox
                              checked={selectedDict[update?._id]}
                              onChange={() =>
                                handleUpdateCheckbox(
                                  subOrderIndex,
                                  updateIndex,
                                  update
                                )
                              }
                            />
                            <p className="text-[13px] d-inline ml-[10px]">
                              {`${
                                update.quantity
                              } Piece made on ${Utils.formateDateForRead(
                                update.date
                              )}`}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <Empty description="No Updated suborder"></Empty>
          )}
        </div>
      </div>
      <div className="flex justify-between mt-4">
        <Button onClick={handlePrev}>Previous</Button>
        <Button
          type="primary"
          onClick={async () => {
            await handleSubmit();
          }}
        >
          Create Challan
        </Button>
      </div>
    </>
  );
};

export default ChallanUpdateList;
