import axios from "axios";
import Utils from "../utilites/Utils";
import { message, notification } from "antd";

export const BASE_URL = process.env.REACT_APP_BASE_URL;
// console.log("BASE_URL", BASE_URL);

async function apiCallGet(url, head = {}) {
  try {
    const token = await Utils.getToken();
    const header = {
      Authorization: token,
      "Content-Type": "application/json",
      ...head,
    };
    return axios({
      url: BASE_URL + url,
      method: "GET",

      headers: header,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        if (error.code === "ERR_NETWORK") {
          return "ERR_NETWORK";
        } else {
          return error;
        }
      });
  } catch (error) {
    throw error;
  }
}
async function apiCallTestGet(url) {
  try {
    return axios({
      url: url,
      method: "GET",
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        if (error.code === "ERR_NETWORK") {
          return "ERR_NETWORK";
        } else {
          return error;
        }
      });
  } catch (error) {
    throw error;
  }
}

async function apiCallPost(url, bodyData, head = {}) {
  try {
    const token = await Utils.getToken();
    const header = {
      Authorization: token,
      "Content-Type": "application/json",
      ...head,
    };
    return axios({
      url: BASE_URL + url,
      method: "POST",
      data: bodyData,
      headers: header,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);

        if (error.code === "ERR_NETWORK") {
          message.error(
            "Our server may be down for maintenance. Please try again later."
          );
          return "ERR_NETWORK";
        } else if (error.code === "ERR_BAD_REQUEST") {
          message.error(error?.response?.data?.message);
        } else {
          return error;
        }
      });
  } catch (error) {
    throw error;
  }
}
async function apiCallPostMultipart(url, bodyData) {
  try {
    const token = await Utils.getToken();
    const header = {
      Authorization: token,
      "Content-Type": "multipart/form-data",
    };
    return axios({
      url: BASE_URL + url,
      method: "POST",
      data: bodyData,
      headers: header,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);

        if (error.code === "ERR_NETWORK") {
          message.error(
            "Our server may be down for maintenance. Please try again later."
          );
          return "ERR_NETWORK";
        } else if (error.code === "ERR_BAD_REQUEST") {
          message.error(error?.response?.data?.message);
        } else {
          return error;
        }
      });
  } catch (error) {
    throw error;
  }
}
async function apiCallPut(url, bodyData) {
  try {
    const token = await Utils.getToken();
    const header = { Authorization: token };
    return axios({
      url: BASE_URL + url,
      method: "PUT",
      data: bodyData,
      headers: header,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);

        if (error.code === "ERR_NETWORK") {
          message.error(
            "Our server may be down for maintenance. Please try again later."
          );
          return "ERR_NETWORK";
        } else if (error.code === "ERR_BAD_REQUEST") {
          message.error(error?.response?.data?.message);
        } else if (error.code === "ERR_BAD_RESPONSE") {
          message.error(error?.response?.data?.message);
        } else {
          return error;
        }
      });
  } catch (error) {
    throw error;
  }
}
async function apiCallPutMultipart(url, bodyData) {
  try {
    const token = await Utils.getToken();
    const header = {
      Authorization: token,
      "Content-Type": "multipart/form-data",
    };
    return axios({
      url: BASE_URL + url,
      method: "PUT",
      data: bodyData,
      headers: header,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);

        if (error.code === "ERR_NETWORK") {
          message.error(
            "Our server may be down for maintenance. Please try again later."
          );
          return "ERR_NETWORK";
        } else {
          return error;
        }
      });
  } catch (error) {
    throw error;
  }
}

async function apiCallTestPost(url, bodyData) {
  // const user = await Util.getUser();
  // let header = {};
  // if (Util.isValidData(user)) {
  //   header.stsid = user.STS_Id;
  // }

  try {
    return axios({
      url: url,
      method: "POST",
      data: bodyData,
      // headers: header,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);

        if (error.code === "ERR_NETWORK") {
          return "ERR_NETWORK";
        } else {
          return error;
        }
      });
  } catch (error) {
    throw error;
  }
}

async function apiCallDelete(url, id) {
  try {
    const token = await Utils.getToken();
    const headers = {
      Authorization: token,
      "Content-Type": "application/json",
    };
    return axios({
      url: BASE_URL + url,
      method: "DELETE",
      headers: headers,
      params: id,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(error);

        if (error.code === "ERR_NETWORK") {
          message.error(
            "Our server may be down for maintenance. Please try again later."
          );
          return "ERR_NETWORK";
        } else if (error.response?.status === 400) {
          message.error(error?.response?.data?.message || "Bad Request");
        } else {
          return error;
        }
      });
  } catch (error) {
    throw error;
  }
}

export const checkServerStatus = async (url) => {
  try {
    const response = await axios.get(url);
    return response.status === 200;
  } catch (error) {
    return false;
  }
};


export default {
  apiCallGet,
  apiCallPut,
  apiCallPost,
  apiCallDelete,
  apiCallTestGet,
  apiCallTestPost,
  apiCallPostMultipart,
  apiCallPutMultipart,
  checkServerStatus
};
