import { Button, Col, DatePicker, Form, Input, message, Row } from "antd";
import { Formik } from "formik";
import React from "react";
import ModalProvider from "../../../../components/common/ModalProvider";
import dayjs from "dayjs";
import * as Yup from "yup";
import moment from "moment";
import ApiCalling from "../../../../network/ApiCalling";
import Utils from "../../../../utilites/Utils";

const UpdateSubOrder = ({
  isCloseModal,
  isModalOpen,
  subOrder,
  handleExpand = () => {},
}) => {
  const handleItemSubmit = (values, resetForm) => {
    ApiCalling.apiCallPost(`suborder-update/create`, values)
      .then((res) => {
        if (Utils.checkAPISuccessStatus(res)) {
          message.success(res?.data?.message);
          isCloseModal();
          resetForm();
          handleExpand(true, subOrder);
        }
      })
      .catch((err) => {
        console.log("ERR", err);
      });
  };
  return (
    <ModalProvider isModalOpen={isModalOpen} OnClose={isCloseModal} width={400}>
      <Formik
        enableReinitialize
        initialValues={{
          subOrder: subOrder?._id,
          date: moment(),
          quantity: "",
        }}
        validationSchema={Yup.object().shape({
          date: Yup.string().required("Date is required"),
          quantity: Yup.string().required("Quantity is required"),
        })}
        onSubmit={(values, { resetForm }) =>
          handleItemSubmit(values, resetForm)
        }
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit} className="mt-[20px]">
              <Row gutter={20} className="pl-[10px] pr-[10px]  ">
                <Col span={24} className="h-[120px]">
                  <Form.Item
                    layout="vertical"
                    className="text-nero text-2xl"
                    label="Date"
                  >
                    <DatePicker
                      name="date"
                      placeholder="Select Date"
                      disabledDate={(current) =>
                        current.isBefore(moment(subOrder?.startDate).startOf('day')) ||
                        current.isAfter(moment(subOrder?.endDate).endOf('day'))
                      }
                      className="h-[45px] w-full"
                      value={values.date ? dayjs(values.date) : null}
                      onChange={(date, dateString) =>
                        setFieldValue(
                          "date",
                          date ? moment(dateString, "YYYY-MM-DD") : null
                        )
                      }
                      onBlur={handleBlur}
                    />
                    {touched.date && errors.date && (
                      <span className="text-red-500 text-[13px]  text-left">
                        {errors.date}
                      </span>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24} className="">
                  <Form.Item
                    layout="vertical"
                    className="text-nero text-2xl "
                    label="Quantity"
                  >
                    <Input
                      name="quantity"
                      value={values.quantity}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.quantity && errors.quantity && (
                      <span className="text-red-500 text-[13px]  text-left">
                        {errors.quantity}
                      </span>
                    )}
                  </Form.Item>
                </Col>
                <Col>
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </Col>
              </Row>
            </form>
          );
        }}
      </Formik>
    </ModalProvider>
  );
};

export default UpdateSubOrder;
