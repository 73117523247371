import React, { useEffect, useState } from "react";
import { Space, Table } from "antd";
import { useNavigate } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import ApiCalling from "../../network/ApiCalling";
import Utils from "../../utilites/Utils";
import Main from "../../components/common/Main";

const ChallanList = () => {
  const navigate = useNavigate();
  const [challanList, setChallanList] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    challanlist();
  }, []);
  function challanlist() {
    setLoading(true);
    ApiCalling.apiCallGet(`order-chalaan/admin/get`)
      .then((res) => {
        console.log("order-chalaan", res.data);
        setChallanList(res?.data);
      })
      .catch((err) => err)
      .finally(() => {
        setLoading(false);
      });
  }

  const handleShowsummary = (challan) => {
    // console.log("suborder", subOrder);
    navigate("/challan-summary", {
      state: { challan, isSummary: true },
    });
  };

  const columns = [
    {
      title: "Challan Date",
      render: (_, date) => Utils.formateDateForRead(date?.date),
    },
    {
      title: "Status",
      render: (_, status) => Utils.challanStatus(status?.status),
    },
    {
      render: (_, challan) => (
        <Space className="flex gap-4">
          <EyeOutlined
            role="button"
            className="text-stroke"
            onClick={() => handleShowsummary(challan)}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <Main>
        <div className="flex items-start justify-between">
          <h1 className="text-extralarge font-bold pb-[30px]">Challan</h1>
          <div className="flex items-center gap-2"></div>
        </div>

        <Table
          loading={loading}
          rowHoverable={false}
          columns={columns}
          className="bg-white rounded-[6px] "
          dataSource={challanList?.map((item) => ({
            ...item,
            key: item?._id,
          }))}
        />
      </Main>
    </>
  );
};

export default ChallanList;
