import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ConfigProvider } from "antd";
import store from "./store";
import { Provider } from "react-redux";


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ConfigProvider
    theme={{
      token: {
        fontFamily: "Inter, sans-serif",
        colorPrimary: "#0075FE",
        colorWhite: "#ffffff",
        borderRadius: 6,
        fontSizeIcon: 24,
        colorBgContainer: "#fff",
        colorPrimaryBorder: "rgba(40,127,113, 15%)",
        colorText: "#141414",
        colorGryis: "#287F71",
        fontSizeHeading1: 20,
      },

      components: {
        DatePicker: {
          controlHeight: 45,

          colorTextPlaceholder: "#287F71",
        },

        Form: {
          lineHeight: 1,
          labelFontSize: 14,

          labelColonMarginInlineStart: 0,
          labelColonMarginInlineEnd: 0,
          itemMarginBottom: 10,
          inlineItemMarginBottom: 0,
          verticalLabelMargin: 0,
          verticalLabelPadding: 0,
          colorError: "red",
        },

        Button: {
          controlHeight: 40,
          borderRadius: 6,
        },
        Input: {
          borderRadius: 6,
          activeBorderColor: "rgba(217,217,217, 50%)",
          controlHeight: 45,
          colorTextPlaceholder: "#287F71",
        },
        Select: {
          activeBorderColor: "rgba(217,217,217, 50%)",
          borderRadius: 6,
          colorPrimaryBorder: "red",

          colorTextPlaceholder: "#287F71",
        },
        Table: {
          headerBg: "#fff",
          headerColor: "#287F71",
          padding: 20,
          headerSplitColor: "none",
        },
      },
    }}
  >
    {/* <React.StrictMode> */}
    <Provider store={store}>
      <App />
    </Provider>
    {/* </React.StrictMode> */}
  </ConfigProvider>
);
