import { createSlice } from "@reduxjs/toolkit";

const orderSummarySlice = createSlice({
  name: "orderSummary",
  initialState: {
    summary: [],
    loading: false,
    error: null,
  },

  reducers: {
    setLoading(state) {
      state.loading = true;
      state.error = null;
    },
    setSummary(state, action) {
      state.loading = false;
      state.summary = action.payload;
    },
    setError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setLoading, setSummary, setError } = orderSummarySlice.actions;

export default orderSummarySlice.reducer;
