// src/components/Sidebar.js
import React, { useEffect, useState } from "react";
import {
  ContainerOutlined,
  DesktopOutlined,
  DashboardOutlined,
  ShoppingCartOutlined,
  WechatWorkFilled,
  WechatWorkOutlined,
} from "@ant-design/icons";
import { Button, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import { LuList } from "react-icons/lu";

const items = [
  {
    key: "1",
    icon: <DashboardOutlined />,
    label: (
      <Link to="/manufacturer-dashboard" className="text-nero no-underline">
        Dashboard
      </Link>
    ),
  },
  {
    key: "2",
    icon: <ContainerOutlined />,
    label: (
      <Link
        to="/manufacturer-dashboard/order-list"
        className="text-nero no-underline"
      >
        Orders
      </Link>
    ),
  },
  {
    key: "3",
    icon: <LuList />,
    label: (
      <Link
        to="/manufacturer-dashboard/challan"
        className="text-nero no-underline"
      >
        Challan
      </Link>
    ),
  },
  // {
  //   key: "4",
  //   icon: <WechatWorkOutlined />,
  //   label: (
  //     <Link to="/manufacturer" className="text-nero no-underline">
  //       Manufacturer
  //     </Link>
  //   ),
  // },
  // {
  //   key: "5",
  //   icon: <ShoppingCartOutlined />,
  //   label: (
  //     <Link to="/raw-material" className="text-nero no-underline">
  //       Raw Material
  //     </Link>
  //   ),
  // },
];

const Logo = () => {
  return (
    <div className="h-[70px] flex items-center justify-start ">
      <h4 className="font-sans font-extrabold mx-[30px] text-large"></h4>
    </div>
  );
};

const Sidebar = ({ collapsed }) => {
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState("");
  // console.log(location);

  const routeToKey = {
    "/manufacturer-dashboard": "1",
    "/manufacturer-dashboard/order-list": "2",
    "/manufacturer-dashboard/challan": "3",
  };

  useEffect(() => {
    const key = routeToKey[location.pathname];
    if (key) {
      setSelectedKey(key);
    }
  }, [location.pathname]);

  return (
    <div
      className={`bg-white h-screen border-r-[1px]  border-stroke-light ${
        collapsed ? "w-[80px]" : "w-[260px]"
      } transition-width duration-300`}
    >
      <Logo />

      <Menu
        inlineCollapsed={collapsed}
        selectedKeys={[selectedKey]}
        items={items}
        className={`custom-menu w-full ant-menu-vertical ant-menu-light   mx-[15px]`}
      />
    </div>
  );
};

export default Sidebar;
