import React, { useEffect, useRef, useState } from "react";
import { Button, Steps, theme } from "antd";
import Main from "../../../../components/common/Main";
import Card from "../../../../components/common/Card";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import ChallanDate from "./challanDate";
import moment from "moment";
import ChallanUpdateList from "./challanUpdateList";
import ChallanSummary from "./challanSummary";
import BreadcrumbComponent from "../../../../components/breadcrumb/BreadcrumbComponent ";

const validationSchema = Yup.object({
  date: Yup.date().required("Date is required"),
  // .min(subOrder.startDate, "Date cannot be before the start date")
  // .max(subOrder.endDate, "Date cannot be after the end date"),
});

const ChallanStepper = () => {
  const { state } = useLocation();
  // console.log("state", state);
  const navigate = useNavigate();
  const [current, setCurrent] = useState(0);
  const [summary, setSummary] = useState({});
  const [formData, setFormData] = useState({ date: moment() });
  const { token } = theme.useToken();
  const challanRef = useRef();

  const breadcrumbItems = [
    { label: "Challan", path: "/manufacturer-dashboard/challan" },
    {
      label:
        current === 0
          ? "Challan date"
          : current === 1
          ? "Create challan for updated subOrder"
          : current === 2
          ? "Summary"
          : "",
    },
  ];

  const handleNext = (values) => {
    setFormData((prev) => ({ ...prev, ...values }));
    setCurrent(current + 1);
  };

  const handlePrev = () => {
    setCurrent(current - 1);
  };

  const handleDateChange = (date) => {
    setFormData((prev) => ({ ...prev, date }));
  };

  const getChallanSummary = (summary) => {
    setSummary(summary);
  };

  useEffect(() => {
    if (state?.isSummary) {
      setCurrent(2);
    }
  }, [state?.isSummary]);
  const steps = [
    {
      title: "Challan Date",
      content: (
        <Formik
          initialValues={formData}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleNext(values);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="w-[50%]">
                <ChallanDate onDateChange={handleDateChange} />
              </div>

              <div className="flex justify-end mt-4">
                <Button type="primary" htmlType="submit">
                  Next
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      ),
    },
    {
      title: "Updated SubOrder",
      content: (
        <>
          <ChallanUpdateList
            onClick={() => setCurrent(current + 1)}
            handlePrev={handlePrev}
            challanDate={formData.date}
            onSummary={getChallanSummary}
          />
        </>
      ),
    },
    {
      title: "Summary",
      content: (
        <>
          <ChallanSummary
            handlePrev={handlePrev}
            summary={state?.isSummary ? state?.subOrder : summary}
            isSummary={state?.isSummary}
          />
        </>
      ),
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const contentStyle = {
    backgroundColor: token.colorWhite,
    borderRadius: token.borderRadiusLG,
    padding: 16,
    marginTop: 16,
  };

  return (
    <>
      <Main>
        <div className="flex items-start justify-between">
          <div className="flex flex-col gap-0 mb-[20px]">
            <h1 className="text-extralarge font-bold">
              {current == 0
                ? "Create Challan Date"
                : current === 1
                ? "Create Challan "
                : current === 2
                ? "Summary"
                : ""}
            </h1>
            <BreadcrumbComponent items={breadcrumbItems} />
          </div>
        </div>
        <Card>
          {!state?.isSummary && (
            <Steps
              size="small"
              className="py-[10px] bg-input px-3 text-[14px] rounded"
              current={current}
              items={items}
            />
          )}
          <div style={contentStyle}>{steps[current].content}</div>
        </Card>
      </Main>
    </>
  );
};

export default ChallanStepper;
