import React, { useEffect, useState } from "react";
import { Button, Flex, Input, message } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";
import FormItem from "antd/es/form/FormItem";
import ApiCalling from "../../network/ApiCalling";
import Utli from "../../utilites/Utils";
import { Link, useNavigate } from "react-router-dom";
import Utils from "../../utilites/Utils";

const Register = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleRegister = async (values, resetForm) => {
    setLoading(true);
    try {
      const res = await ApiCalling.apiCallPost("admin/signup", values);
      if (Utils.checkAPISuccessStatus(res)) {
        Utli.saveToken(res?.data?.token);
        Utli.saveRegisteredUser(res?.data?.user);

        message.success(res?.data?.message);
        resetForm();
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <h3 className="text-[26px] font-bold py-3">Sign Up</h3>
      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          email: "",
          password: "",
        }}
        validationSchema={Yup.object().shape({
          first_name: Yup.string().required("First Name is required"),
          last_name: Yup.string().required("Last Name is required"),
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          password: Yup.string().max(255).required("Password is required"),
        })}
        onSubmit={(values, { resetForm }) => handleRegister(values, resetForm)}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Flex vertical gap={errors.email && errors.password ? 10 : 25}>
              <div>
                <Input
                  placeholder="First Name"
                  variant="filled"
                  name="first_name"
                  value={values.first_name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  size="large"
                  className="w-full h-[45px] text-[14px] font-sans font-medium bg-input rounded-[6px] focus:outline-none focus:ring-1 focus:ring-stroke-light "
                />
                {touched.first_name && errors.first_name && (
                  <span
                    error
                    id="helper-text-phone-signin"
                    className="px-[6px] text-[12px] text-red-500 "
                  >
                    {errors.first_name}
                  </span>
                )}
              </div>
              <div>
                <Input
                  placeholder="Last Name"
                  variant="filled"
                  name="last_name"
                  value={values.last_name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  size="large"
                  className="w-full h-[45px] text-[14px] font-sans font-medium bg-input rounded-[6px] focus:outline-none focus:ring-1 focus:ring-stroke-light "
                />
                {touched.last_name && errors.last_name && (
                  <span
                    error
                    id="helper-text-phone-signin"
                    className="px-[6px] text-[12px] text-red-500 "
                  >
                    {errors.last_name}
                  </span>
                )}
              </div>
              <div>
                <Input
                  placeholder="Email address"
                  variant="filled"
                  name="email"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  size="large"
                  className="w-full h-[45px] text-[14px] font-sans font-medium bg-input rounded-[6px] focus:outline-none focus:ring-1 focus:ring-stroke-light "
                />
                {touched.email && errors.email && (
                  <span
                    error
                    id="helper-text-phone-signin"
                    className="px-[6px] text-[12px] text-red-500 "
                  >
                    {errors.email}
                  </span>
                )}
              </div>
              <div>
                <Input.Password
                  placeholder="Password"
                  variant="filled"
                  name="password"
                  type="password"
                  // size="large"
                  disabled={loading}
                  onBlur={handleBlur}
                  visibilityToggle={{
                    visible: passwordVisible,
                    onVisibleChange: setPasswordVisible,
                  }}
                  value={values.password}
                  className="w-full h-[45px] text-[14px] font-sans font-medium bg-input rounded-[6px] focus:outline-none focus:ring-1 focus:ring-stroke-light"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                {touched.password && errors.password && (
                  <span
                    className="px-[6px] text-[12px] text-red-500"
                    error
                    id="helper-text-password-signin"
                  >
                    {errors.password}
                  </span>
                )}
              </div>
              <Button
                loading={loading}
                // disabled={isSubmitting}
                type="primary"
                className="bg-primary font-sans text-white h-[45px] mb-10"
                htmlType="submit"
              >
                Sign Up
              </Button>
            </Flex>
          </form>
        )}
      </Formik>
      <FormItem
        className="pb-0 mb-0  font-sans text-[12px]  text-red-800"
        error
        id="helper-text-password-signin"
      >
        I have an account{" "}
        <Link
          to={"/login"}
          className=" underline text-blue-600 hover:text-blue-400 font-medium"
        >
          Login
        </Link>
      </FormItem>
    </>
  );
};

export default Register;
