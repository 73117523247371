import { Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import Main from "../../../../components/common/Main";
import BreadcrumbComponent from "../../../../components/breadcrumb/BreadcrumbComponent ";
import Utils from "../../../../utilites/Utils";
import ApiCalling from "../../../../network/ApiCalling";

const CancelChallanList = () => {
  const [cancelList, setCancelList] = useState([]);
  const [loading, setLoading] = useState(false);

  const breadcrumbItems = [
    { label: "Challan", path: "/manufacturer-dashboard/challan" },
    {
      label: "Cancelled Challan",
    },
  ];

  useEffect(() => {
    getCancelledList();
  }, []);

  const getCancelledList = () => {
    setLoading(true);
    ApiCalling.apiCallGet(`order-chalaan/get-canceled`)
      .then((res) => {
        console.log("cancelled list", res.data);
        setCancelList(res?.data);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "Cancelled Date",
      render: (_, record) => (
        <Space>{Utils.formateDateForRead(record.date)}</Space>
      ),
    },
    {
      title: "Challan No",
      render: (_, record) => record?.chalaanNo,
    },
    {
      title: "Status",
      render: (_, record) => Utils.challanStatus(record?.status),
    },
  ];
  return (
    <>
      <Main>
        <div className="flex items-start justify-between">
          <div className="flex flex-col gap-0 mb-[20px]">
            <h1 className="text-extralarge font-bold">Cancelled Challan</h1>
            <BreadcrumbComponent items={breadcrumbItems} />{" "}
          </div>
        </div>

        <Table
          rowHoverable={false}
          loading={loading}
          locale={{ emptyText: "You don't have any cancelled challan." }}
          columns={columns}
          className="bg-white rounded-[6px] "
          dataSource={cancelList?.map((item) => ({
            ...item,
            key: item?._id,
          }))}
          pagination={false}
        />
      </Main>
    </>
  );
};

export default CancelChallanList;
