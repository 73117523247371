import React, { useEffect, useState } from "react";
import Main from "../../components/common/Main";
import { Button, Space, Spin, Table } from "antd";
import { BiPlus } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import ApiCalling from "../../network/ApiCalling";
import Utils from "../../utilites/Utils";

const RawMaterial = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [material, setMaterial] = useState([]);

  useEffect(() => {
    getRawMaterialList();
  }, []);
  const getRawMaterialList = () => {
    setLoading(true);
    ApiCalling.apiCallGet("raw-material/get")
      .then((res) => {
        setLoading(false);
        if (Utils.checkAPISuccessStatus(res)) {
          setMaterial(
            res.data.map((material) => ({ ...material, key: material._id }))
          );
        }
      })
      .catch((err) => console.error("ERROR", err));
  };

  const handleEdit = (raw_materialId) => {
    navigate(`/create-material`, {
      state: raw_materialId,
    });
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text) => <span className="">{text}</span>,
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Per Piece",
      dataIndex: "piece",
      key: "piece",
      render: (text, record) => (
        <span>
          {text} {record.unit}
        </span>
      ),
    },

    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <FiEdit
            role="button"
            className="text-neutral-400 cursor-pointer"
            onClick={() => handleEdit(record)}
          />
        </Space>
      ),
    },
  ];

  return (
    <Main>
      <div className="flex items-start justify-between">
        <h1 className="text-extralarge font-bold pb-[30px]">Raw Material</h1>
        <Button
          type="primary"
          className="rounded h-[40px] font-sans "
          icon={<BiPlus size={20} className="mx-0 space-x-0 px-0 m-0" />}
          onClick={() => navigate("/create-material")}
        >
          Create Material
        </Button>
      </div>

      <Table
        loading={loading}
        columns={columns}
        dataSource={material.map((item) => ({ ...item, key: item._id }))}
        className="bg-white  rounded-[6px] font-serif"
        pagination={true}
      />
    </Main>
  );
};

export default RawMaterial;
