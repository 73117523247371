import React, { useState } from "react";
import { Button, Flex, Input, message, notification } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";
import FormItem from "antd/es/form/FormItem";
import Utli from "../../utilites/Utils";
import ApiCalling from "../../network/ApiCalling";
import { Link, useNavigate } from "react-router-dom";
import Utils from "../../utilites/Utils";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleLogin = async (values, resetForm) => {
    setLoading(true);
    try {
      const res = await ApiCalling.apiCallPost("admin/login", values);
      if (Utils.checkAPISuccessStatus(res)) {
        Utli.saveToken(res?.data?.token);
        Utli.saveRegisteredUser(res?.data?.user);
        // notification.success({ message: res?.data?.message,});
        message.success(res?.data?.message);
        resetForm();
        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <h3 className="text-[26px] font-bold py-3">Sign In</h3>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          password: Yup.string().max(255).required("Password is required"),
        })}
        onSubmit={(values, { resetForm }) => handleLogin(values, resetForm)}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Flex vertical gap={errors.email && errors.password ? 15 : 30}>
              <div>
                <Input
                  placeholder="Email address"
                  variant="filled"
                  name="email"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  readOnly={loading}
                  size="large"
                  className="w-full h-[45px] text-[14px] font-sans font-medium  bg-input rounded-[6px] focus:outline-none focus:ring-1 focus:ring-stroke-light "
                />
                {touched.email && errors.email && (
                  <span
                    id="helper-text-phone-signin"
                    className="pb-0 mb-0  font-sans px-[6px] text-[12px] text-red-800 "
                  >
                    {errors.email}
                  </span>
                )}
              </div>
              <div>
                <Input.Password
                  placeholder="Password"
                  variant="filled"
                  type="password"
                  name="password"
                  // size="large"
                  onBlur={handleBlur}
                  // disabled={loading}
                  visibilityToggle={{
                    visible: passwordVisible,
                    onVisibleChange: setPasswordVisible,
                  }}
                  className="w-full h-[45px] text-[14px] font-sans font-medium  bg-input rounded-[6px] focus:outline-none focus:ring-1 focus:ring-stroke-light"
                  readOnly={loading}
                  value={values.password}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />

                {touched.password && errors.password && (
                  <span className="pb-0 mb-0  font-sans px-[6px] text-[12px]  text-red-800">
                    {errors.password}
                  </span>
                )}
              </div>

              <Button
                loading={loading}
                // disabled={isSubmitting}
                type="primary"
                className="bg-primary font-sans h-[45px] mb-10"
                htmlType="submit"
              >
                Sign In
              </Button>
            </Flex>
          </form>
        )}
      </Formik>
      <FormItem className="pb-0 mb-0  font-sans text-[12px]  text-red-800">
        I don't have an account{" "}
        <Link
          to={"/register"}
          className=" underline text-blue-600 hover:text-blue-400 font-medium"
        >
          Register
        </Link>
      </FormItem>
    </>
  );
};
export default Login;
