import { LogoutOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { Button, message, Popconfirm } from "antd";
import React from "react";

import { useNavigate } from "react-router-dom";
import Utils from "../../../utilites/Utils";

const Topbar = ({ collapsed, toggleCollapsed }) => {
  const navigate = useNavigate();
  const logOutManufacturer = () => {
    Utils.removeManufactureToken();
    Utils.removeToken();
    Utils.removeManufactureUser();

    message.success("Manufacturer Logout Successful!");
    setTimeout(() => {
      navigate("/manufacturer-login");
    }, 1000);
  };

  return (
    <div className="h-[70px] flex items-center mx-4 justify-between   ">
      <div className="">
        <Button
          type="primary"
          onClick={toggleCollapsed}
          className="flex items-center justify-center w-full shadow-none bg-neutral-light hover:bg-blue-600 text-stroke"
        >
          {collapsed ? <MenuFoldOutlined /> : <MenuFoldOutlined />}
        </Button>
      </div>
      <div>
        <Popconfirm
          destroyTooltipOnHide
          icon={<LogoutOutlined />}
          title="Logout"
          description="Are you sure want to Logout?"
          onConfirm={logOutManufacturer}
          okText="Yes"
          cancelText="No"
          placement="bottomLeft"
        >
          <Button className="border-none font-medium text-nero shadow-none">
            Sign Out
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
};

export default Topbar;
