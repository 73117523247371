import React from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import "./App.css";
import AdminLayout from "./layouts/AdminLayout";
import Dashboard from "./pages/Dashboard";
import Orders from "./pages/orders/OrdersList";
import Login from "./Auth/authContainer/Login";
import AuthContainer from "./Auth/authContainer/AuthContainer";
import Register from "./Auth/authContainer/Register";
import ProtectedRoute from "./components/ProtectedRoute";
import CreateVendor from "./pages/vendor/createVendor";
import Manufacturer from "./pages/manufacturer/Manufacturer";
import Vendor from "./pages/vendor/vendor";
import CreateManufaturer from "./pages/manufacturer/addManufacturer";
import RawMaterialForm from "./pages/raw-material/RawMaterialForm";
import RawMaterial from "./pages/raw-material/RawMaterial";
import CreateOrder from "./pages/orders/OrderStepper";
import ManufacturerLogin from "./Auth/authContainer/manufactureAuth/manufacturerLogin";
import ManufacturerDashboard from "./layouts/manufacturerLayout/pages/dashboard";
import ManuFactureLayout from "./layouts/manufacturerLayout/ManuFactureLayout";
import OrdersList from "./layouts/manufacturerLayout/pages/orders/ordersList";
import Challan from "./layouts/manufacturerLayout/pages/challan/challan";
import ChallanStepper from "./layouts/manufacturerLayout/pages/challan/challanStepper";
import CancelChallanList from "./layouts/manufacturerLayout/pages/challan/cancelChallanList";
import ChallanList from "./pages/challan/challanList";
import ChallanSummary from "./pages/challan/summary";
import ItemStep from "./pages/orders/item/ItemStep";
import { BASE_URL, checkServerStatus } from "./network/ApiCalling";
import { notification } from "antd";
import { useCallback, useEffect, useState } from "react";
import store from "./store";
import OrderPlan from "./pages/orders/orderPlanning/orderPlan";
import Card from "antd/es/card/Card";

function App() {
  // console.log("STORE", store.getState().programming);
  // const [isServerUp, setIsServerUp] = useState(true);

  // const fetchServerStatus = useCallback(async () => {
  //   const status = await checkServerStatus(BASE_URL);
  //   setIsServerUp(status);
  // }, []);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     fetchServerStatus();
  //   }, 3000);

  //   return () => clearInterval(interval);
  // }, [fetchServerStatus]);

  // useEffect(() => {
  //   if (!isServerUp) {
  //     notification.info({
  //       message: isServerUp
  //         ? "Server is Online."
  //         : "Server is down! Please try again later!",
  //     });
  //   }
  // }, [isServerUp]);
  return (
    <Router>
      <Routes>
        {/* Public route for login */}
        <Route path="login" element={<AuthContainer children={<Login />} />} />
        <Route
          path="manufacturer-login"
          element={
            <AuthContainer>
              <ManufacturerLogin />
            </AuthContainer>
          }
        />
        <Route
          path="manufacturer-dashboard"
          element={
            <ProtectedRoute role="manufacturer">
              <ManuFactureLayout />
            </ProtectedRoute>
          }
        >
          <Route
            path=""
            element={
              <ProtectedRoute>
                <ManufacturerDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="order-list"
            element={
              <ProtectedRoute>
                <OrdersList />
              </ProtectedRoute>
            }
          />
          <Route
            path="challan"
            element={
              <ProtectedRoute>
                <Challan />
              </ProtectedRoute>
            }
          />
          <Route
            path="create-challan"
            element={
              <ProtectedRoute>
                <ChallanStepper />
              </ProtectedRoute>
            }
          />
          <Route
            path="cancel-challan"
            element={
              <ProtectedRoute>
                <CancelChallanList />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="*" element={<PageNotFound />} />

        <Route
          path="register"
          element={<AuthContainer children={<Register />} />}
        />

        <Route
          path="/"
          element={
            <ProtectedRoute role="admin">
              <AdminLayout />
            </ProtectedRoute>
          }
        >
          <Route
            path="dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />

          <Route path="*" element={<PageNotFound />} />
          <Route
            path="vendor"
            element={
              <ProtectedRoute>
                <Vendor />
              </ProtectedRoute>
            }
          />
          <Route
            path="create-vendor"
            element={
              <ProtectedRoute>
                <CreateVendor />
              </ProtectedRoute>
            }
          />
          <Route
            path="orders"
            element={
              <ProtectedRoute>
                <Orders />
              </ProtectedRoute>
            }
          />
          <Route
            path="manufacturer"
            element={
              <ProtectedRoute>
                <Manufacturer />
              </ProtectedRoute>
            }
          />
          <Route
            path="create-manufacturer"
            element={
              <ProtectedRoute>
                <CreateManufaturer />
              </ProtectedRoute>
            }
          />
          <Route
            path="raw-material"
            element={
              <ProtectedRoute>
                <RawMaterial />
              </ProtectedRoute>
            }
          />
          <Route
            path="create-material"
            element={
              <ProtectedRoute>
                <RawMaterialForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="create-order"
            element={
              <ProtectedRoute>
                <CreateOrder />
              </ProtectedRoute>
            }
          />
          <Route
            path="create-item"
            element={
              <ProtectedRoute>
                <ItemStep />
              </ProtectedRoute>
            }
          />

          <Route
            path="challan"
            element={
              <ProtectedRoute>
                <ChallanList />
              </ProtectedRoute>
            }
          />
          <Route
            path="challan-summary"
            element={
              <ProtectedRoute>
                <ChallanSummary />
              </ProtectedRoute>
            }
          />
          <Route
            path="order-plan"
            element={
              <ProtectedRoute>
                <OrderPlan />
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;

export const PageNotFound = () => {
  return <h3>Page Not Found</h3>;
};
