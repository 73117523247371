import React, { useEffect, useState } from "react";
import Main from "../../components/common/Main";
import { Button, Card, Col, Form, Input, message, Row } from "antd";
import BreadcrumbComponent from "../../components/breadcrumb/BreadcrumbComponent ";
import { Formik } from "formik";
import * as Yup from "yup";

import ApiCalling from "../../network/ApiCalling";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Utils from "../../utilites/Utils";

const RawMaterialForm = () => {
  const { state } = useLocation();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [vendorData, setVendorData] = useState(null);
  const breadcrumbItems = [
    { label: "Raw Material", path: "/raw-material" },
    { label: state?._id ? "Edit Raw Material" : "Create Raw Material" },
  ];

  useEffect(() => {
    if (state) {
      setVendorData(state);
    }
  }, [state]);

  const handleFormSubmit = (values, resetForm) => {
    setLoading(true);
    const apiCall = state
      ? ApiCalling.apiCallPut(`raw-material/update`, {
          ...values,
          id: state?._id,
        })
      : ApiCalling.apiCallPost(`raw-material/create`, values);

    apiCall
      .then((res) => {
        console.log("RES", res);

        if (Utils.checkAPISuccessStatus(res)) {
          message.success(res.data.message);
          resetForm();
          setTimeout(() => {
            navigate("/raw-material");
          }, 1000);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Main>
      <div className="flex items-start justify-between">
        <div className="flex flex-col gap-0">
          <h1 className="text-extralarge font-bold">
            {state ? "Edit Raw Material" : "Create Raw Material"}
          </h1>
          <BreadcrumbComponent items={breadcrumbItems} />
        </div>
      </div>

      <Card className="mt-[30px]">
        <Formik
          enableReinitialize
          initialValues={
            vendorData || {
              title: "",
              unit: "",
              piece: "",
            }
          }
          validationSchema={Yup.object().shape({
            title: Yup.string().required("Title is required"),
            unit: Yup.string().required("Unit is required"),
            piece: Yup.string().required("Piece is required"),
          })}
          onSubmit={(values, { resetForm }) =>
            handleFormSubmit(values, resetForm)
          }
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Row gutter={40} className="p-[30px]">
                <Col span={12}>
                  <Form.Item
                    layout="vertical"
                    className="text-nero text-2xl"
                    label="Title"
                  >
                    <Input
                      name="title"
                      value={values?.title}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.title && errors.title && (
                      <Form.Item className="text-red-500  ">
                        {errors.title}
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    layout="vertical"
                    className="text-nero text-2xl"
                    label="Unit"
                  >
                    <Input
                      name="unit"
                      value={values.unit}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.unit && errors.unit && (
                      <Form.Item className="text-red-500  ">
                        {errors.unit}
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    layout="vertical"
                    className="text-nero text-2xl"
                    label="Per Piece"
                  >
                    <Input
                      name="piece"
                      value={values.piece}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.piece && errors.piece && (
                      <Form.Item className="text-red-500  ">
                        {errors.piece}
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} className="pl-[30px]  justify-end">
                <Col>
                  <Button
                    onClick={() => navigate(-1)}
                    type=""
                    htmlType="button"
                    className="rounded h-[40px] font-sans  "
                  >
                    Cancel
                  </Button>
                </Col>
                <Col className=" ">
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="rounded h-[40px] font-sans  "
                  >
                    {state?._id ? "Save Changes" : "Save"}
                  </Button>
                </Col>
              </Row>
            </form>
          )}
        </Formik>
      </Card>
    </Main>
  );
};

export default RawMaterialForm;
