import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  itemList: [],
  manufacturerList: [],
  programmingList: [],
};

const addProgrammingSlice = createSlice({
  name: "programming",
  initialState,
  reducers: {
    setItemList(state, action) {
      state.itemList = action.payload;
    },
    setProgrammingList(state, action) {
      state.programmingList = action.payload;
    },
    setManufacturerList(state, action) {
      state.manufacturerList = action.payload;
    },
  },
});

export const {
  setItemList,
  programmingList,
  manufacturerList,
  setManufacturerList,
  setProgrammingList,
} = addProgrammingSlice.actions;

export default addProgrammingSlice.reducer;
