import React, { useEffect, useMemo, useState } from "react";
import Utils from "../utilites/Utils";
import Main from "../components/common/Main";
import Card from "../components/common/Card";
import { Badge, Button, Calendar, Col, List, Row } from "antd";
import ApiCalling from "../network/ApiCalling";
import moment from "moment";
import { Collapse } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import WeekCalendar from "../components/calender/weekCalender";

const Dashboard = () => {
  const [inventoryData, setInventryData] = useState([]);
  const [upcomingM, setupcomingM] = useState([]);
  const [loading, setLoading] = useState(false);

  async function updateUpcomiing(start, end) {
    let url = `manufacturer/next-material-report`;
    if (start && end) url = url + `?startDate=${start}&endDate=${end}`;
    getInventoryNextMaterial(url);
  }

  useEffect(() => {
    getInventory();
  }, []);

  function getInventory() {
    setLoading(true);
    ApiCalling.apiCallGet(`manufacturer/inventory-update`)
      .then((res) => {
        setInventryData(res?.data);
      })
      .catch((err) => err)
      .finally(() => {
        setLoading(false);
      });
  }
  async function getInventoryNextMaterial(
    url = `manufacturer/next-material-report`
  ) {
    setLoading(true);
    try {
      const res = await ApiCalling.apiCallGet(url);
      const data = res.data;
      if (data) {
        setupcomingM(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const upcomingKeys = useMemo(() => {
    return upcomingM ? Object.keys(upcomingM) : [];
  }, [upcomingM]);

  const getListData = (value) => {
    const listData = [];
    const matchValue = upcomingKeys.find((key) =>
      moment(key).isSame(moment(value).toDate(), "day")
    );

    if (matchValue) {
      return (
        upcomingM[matchValue]?.map((material) => ({
          type: "success",
          content: material.name,
        })) || []
      );
    }

    return listData;
  };

  const transformData = (data) => {
    const events = [];
    for (const date in data) {
      const m = data[date]?.map(
        (item) => ({ name: item?.name, machineUsed: item?.machineUsed })
        // (item) => (item.name) //${item.name}-${item.machineUsed}
      );
      events.push({ date, m });
    }
    return events;
  };
  const events = transformData(upcomingM);
  // console.log(events);

  return (
    <>
      <div className="px-[30px]">
        <h1>Welcome! {Utils.getRegisteredUser()?.first_name}</h1>{" "}
      </div>
      <Main>
        <Card className=" ">
          <Row gutter={20}>
            {/* <Col span={8}></Col> */}
            <Col span={12}>
              <Card className="p-0">
                <p className="pb-[4px] font-semibold text-[20px] text-stroke mb-1">
                  Material available report
                </p>
                <List
                  loading={loading}
                  header=""
                  dataSource={inventoryData?.map((item) => ({
                    ...item,
                    key: item._id,
                  }))}
                  renderItem={(item) => (
                    <>
                      <ListData item={item} />
                    </>
                  )}
                />
              </Card>
            </Col>
            <Col span={12}>
              {/* <CalenderSystem
                  upcomingM={upcomingM}
                  mode={mode}
                  setMode={setMode}
                /> */}
              {/* <WeeklyCalendar
                events={() => dateCellRender(events)}
                dateCellRender={dateCellRender}
                onEventClick={(event) => console.log("Event clicked:", event)}
                onSelectDate={(date) => console.log("Date selected:", date)}
                showTime={false}
                weekends={false}
                renderSlot={(slot) => null}
                customRender={(calendarData) => {
                  // Exclude hours column logic
                  return calendarData.filter((item) => item.type !== "hours");
                }}
                hideHoursColumn={true}
              /> */}
              <WeekCalendar
                events={events}
                callback={updateUpcomiing}
                loading={loading}
              />
            </Col>
          </Row>
        </Card>
      </Main>
    </>
  );
};

export default Dashboard;

function ListData({ item }) {
  const { Panel } = Collapse;
  return (
    <div className="p-2 bg-white shadow-dark rounded border-1 border-stroke mb-4">
      <Collapse
        ghost
        expandIcon={({ isActive }) =>
          isActive ? (
            <MinusOutlined className="text-[14px]" />
          ) : (
            <PlusOutlined className="text-[14px]" />
          )
        }
      >
        <Panel
          header={
            <p className="font-semibold text-stroke ">
              {item?.name + " need material immediately"}
            </p>
          }
          key="1"
        >
          <div className="space-y-3">
            {item?.inventory?.map((inv, index) => {
              const isPastDate = moment(inv?.tillDate).isBefore(
                moment(),
                "day"
              );

              return (
                <div
                  key={index}
                  className="flex items-center justify-between bg-gray-50 p-2 rounded hover:bg-gray-100 transition-all duration-200"
                >
                  <p className="text-xs text-green-500 font-medium">
                    {isPastDate
                      ? ""
                      : `${item?.name} needs ${
                          inv?.material?.title
                        } before ${moment(inv?.tillDate).format(
                          "MMM DD, YYYY"
                        )}`}
                    {isPastDate && (
                      <span
                        className={`text-xs font-semibold text-red-500`}
                      >{`${item?.name} 
                      has exhausted ${
                        inv?.material?.title
                      }. Please add before ${moment(inv?.tillDate).format(
                        "MMM DD, YYYY"
                      )} immediately.`}</span>
                    )}
                  </p>
                </div>
              );
            })}
          </div>
        </Panel>
      </Collapse>
    </div>
  );
}

function CalenderSystem({ upcomingM, mode, setMode }) {
  const upcomingKeys = useMemo(() => {
    return Object.keys(upcomingM);
  }, [upcomingM]);

  const getListData = (value) => {
    let listData = [];
    // console.log(value.toISOString(), "day");
    const matchValue = upcomingKeys.find((i) =>
      moment(i).isSame(moment(new Date(value).toDateString()), "day")
    );
    if (matchValue) {
      const listD =
        upcomingM[matchValue]?.map((val) => ({
          type: "success",
          content: <span className="text-[12px]">{val.name}</span>,
        })) || [];
      return listD;
    }
    return [];
  };

  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData.map((item) => (
          <li key={item.content}>
            <Badge status={item.type} text={item.content} />
          </li>
        ))}
      </ul>
    );
  };
  const cellRender = (current, info) => {
    if (info.type === "date") return dateCellRender(current);
    // if (info.type === "month") return monthCellRender(current);
    return info.originNode;
  };
  const headerRender = ({ value, type, onChange, onTypeChange }) => {
    const start = value.clone().startOf("week");
    const end = value.clone().endOf("week");

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px 16px",
        }}
      >
        <div>
          {`Week: ${start.format("MMM D")} - ${end.format("MMM D, YYYY")}`}
        </div>
        <div className="flex gap-1">
          <Button onClick={() => onChange(value.clone().subtract(1, "week"))}>
            <BiChevronLeft size={24} />
          </Button>
          <Button onClick={() => onChange(value.clone().add(1, "week"))}>
            <BiChevronRight size={24} />
          </Button>
        </div>
      </div>
    );
  };
  return (
    <Calendar
      className="small-chevron"
      cellRender={cellRender}
      headerRender={headerRender}
      mode={mode}
      onPanelChange={(date, newMode) => setMode(newMode)}
    />
  );
}
