import React from "react";
import Login from "./Login";

const AuthContainer = ({ children }) => {
  return (
    <div className="flex items-center justify-center h-screen ">
      <div className="col-span-4 w-full max-w-md p-[30px] bg-white rounded-[6px] drop-shadow-2xl   shadow-slate-100  ">
        {children}
      </div>
    </div>
  );
};

export default AuthContainer;
