import React, { useEffect, useState } from "react";
import Card from "../common/Card";
import { Divider, List } from "antd";
import Utils from "../../utilites/Utils";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrderItemsList } from "../../redux/thunk/actions";

const ProgramSummary = ({ order }) => {
  const dispatch = useDispatch();
  // console.log('order-------',order);
  
  const { orderDate, deliveryDate, name, _id } = order;
  const itemList = useSelector((state) => state?.programming?.itemList);

  useEffect(() => {
    // dispatch(fetchOrderItemsList(_id));
  }, [_id]);

  return (
    <div className="mt-2">
      <div className="grid grid-cols-3 gap-2">
        <Card className="p-[15px]">
          <div className="flex justify-between py-2 ">
            <div className="flex flex-col">
              <h6 className="mb-[4px]">Program Summary</h6>
              <p className="mb-0 text-[14px] font-medium text-stroke  ">
                {name}
              </p>
            </div>
            <div>
              <p className="mb-[2px] text-[12px] text-stroke">
                Order date:{" "}
                <span className=" text-nero font-bold text-[12px]">
                  {Utils.formateDateForRead(orderDate)}
                </span>
              </p>
              <p className="mb-[2px] text-[12px] text-stroke">
                Delivery date:{" "}
                <span className="  text-nero font-bold  text-[12px]">
                  {Utils.formateDateForRead(deliveryDate)}
                </span>
              </p>
            </div>
          </div>
          <Divider orientation="left" className="my-[10px]"></Divider>
          <List
            locale={{
              emptyText: "You don't have any Item's programming.",
            }}
            dataSource={itemList}
            renderItem={(item) => (
              <List.Item>
                <div className="grid grid-cols-3  w-full  gap-[20px]  justify-between ">
                  <div className="flex justify-start gap-[10px] items-center">
                    <p className="font-semiBold text-nowrap mb-0">
                      {item?.title}
                    </p>
                  </div>

                  <div className="flex justify-center place-content-center items-center">
                    {" "}
                    {Utils.checkStatus(item?.status)}
                  </div>
                  <div>
                    <p className="mb-0 text-[10px]">Remaining / Total</p>
                    {item?.remainingQuantity + " / " + item?.quantity}
                  </div>
                </div>
              </List.Item>
            )}
          />
        </Card>
      </div>
    </div>
  );
};

export default ProgramSummary;
