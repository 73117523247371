import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiCalling from "../../network/ApiCalling";
import Utils from "../../utilites/Utils";
import { setItemList } from "../slices/addProgrammingSlice";
import {
  setSummary,
  loading,
  setLoading,
  error,
  setError,
} from "../slices/summarySlice";

export const fetchOrderItemsList = createAsyncThunk(
  "programming/fetchOrderItemsList",
  async (_id, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiCalling.apiCallGet(
        `item/remaining-item/${_id}`
      );
      if (Utils.checkAPISuccessStatus(response)) {
        dispatch(setItemList(response?.data));
        return response?.data;
      } else {
        return rejectWithValue("Failed to fetch items");
      }
    } catch (error) {
      console.error("ERROR", error);
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);

export const fetchOrderSummary = (orderId) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const response = await ApiCalling.apiCallGet(`order/summary/${orderId}`);
    // console.log("Response:", response);

    if (Utils.checkAPISuccessStatus(response)) {
      dispatch(setSummary(response?.data));
    } else {
      dispatch(setError("Failed to fetch items"));
    }
  } catch (error) {
    dispatch(setError(error?.response?.data || "Something went wrong"));
  }
};
