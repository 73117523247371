import { Col } from "antd";
import React from "react";
import { ITEM_IMAGE_URL } from "../../../utilites/Const";
import Placeholder from "../../../assests/icons/imag-holder.png";
import { FiEdit } from "react-icons/fi";
import knitting from "../../../assests/icons/knitting.png";

const ItemCard = ({ item, handleEditFn, handleRawDetails }) => {
  const handleEdit = (item) => {
    handleEditFn(item);
  };

  const handleDetails = (item) => {
    handleRawDetails(true, item);
  };

  return (
    <Col
      className="gutter-row mb-[20px] "
      xs={24}
      sm={12}
      lg={6}
      md={6}
      xl={6}
      xxl={4}
    >
      <div
        className=" shadow-spread py-[15px] px-[14px] rounded h-[100%]"
        cover={<img alt={item?.title} src={item?.image} />}
      >
        
          <img
            src={item?.image ? ITEM_IMAGE_URL + item?.image : Placeholder}
            alt=""
            className="object-cover w-[100%]   h-[250px] mb-[16px]"
          />
        
        <h4 className="text-[14px] font-semiBold  mb-0">{item?.title}</h4>
        <span className="inline-flex items-center rounded-md bg-blue-200 mt-2 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
          Qty: {item?.quantity}
        </span>
        <div className="flex items-center justify-between mt-[10px] gap-[10px]">
          <FiEdit
            role="button"
            className="text-nero"
            onClick={() => {
              handleEdit(item);
            }}
          />
          <div className="flex  items-start">
            <img
              src={knitting}
              role="button"
              width={18}
              onClick={() => handleDetails(item)}
            />
            <span className="">{item?.rawMaterialCount}</span>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default ItemCard;
