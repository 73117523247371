import { Button, Divider } from "antd";
import React, { forwardRef, useRef } from "react";
import Utils from "../../../../utilites/Utils";
import { useReactToPrint } from "react-to-print";

const ChallanSummary = ({ summary, handlePrev, isSummary }) => {
  return (
    <>
      <Invoice
        summary={summary}
        handlePrev={handlePrev}
        isSummary={isSummary}
      />
    </>
  );
};

export default ChallanSummary;

const Invoice = ({ handlePrev, summary, isSummary }) => {
  console.log("Invoice REf isSummary", summary);
  const invoiceRef = useRef();
  const print = useReactToPrint({ contentRef: invoiceRef });

  return (
    <>
      <div
        ref={invoiceRef}
        className="border-1 print-center max-w-2xl mx-auto border-stroke-light"
      >
        <div className="flex p-6 justify-between items-center pb-4  border-b">
          <div>
            <p className="mb-[4px] text-sm ">
              From:{" "}
              <span className="font-semiBold">
                {Utils.getManufactureUser().name}
              </span>
            </p>
            <p className="mb-[4px] text-sm ">
              {" "}
              To: -{/* {Utils.getManufactureUser().name} */}
            </p>
          </div>
          <div>
            <p className="text-sm font-medium text-gray-600 mb-[4px]">
              Challan No: {summary?.chalaanNo}
            </p>

            <p className="text-sm font-medium text-gray-600 mb-[4px]">
              Challan Date: {Utils.formateDateForRead(summary?.date)}
            </p>
            <p
              className={`text-sm text-${Utils.challanStatus(summary?.status)}`}
            >
              Status: {Utils?.challanStatus(summary?.status)}
            </p>
          </div>
        </div>

        <div className="">
          <h4 className="font-bold text-[16px] px-2 py-2 pb-0">Sub Orders</h4>
          <Divider className="my-[10px]" />
          <div className="grid grid-cols-4   px-2 text-sm font-semibold text-gray-600 border-b pb-2  ">
            <p className="mb-0">#</p>
            <p className="mb-0">Name</p>
            <p className="mb-0">Date</p>
            <p className="mb-0">Quantity</p>
          </div>

          {summary?.subOrderUpdates?.map((product) => (
            <div
              key={product?._id}
              className="grid grid-cols-4 mb-0 border-b border-stroke-light  px-2   items-center text-sm text-gray-800 py-3   last:border-b-0"
            >
              <p className="mb-0">
                {Utils.repraseManuOrderId(product?.subOrder?.subOrderId)}
              </p>
              <p className="mb-0">{product?.subOrder?.item?.title}</p>
              <p className="mb-0">{Utils.formateDateForRead(product?.date)}</p>
              <p className="mb-0">{product?.quantity}</p>
            </div>
          ))}
        </div>

        <div className="flex justify-end mt-0  ">
          {/* <p className="text-lg font-semibold text-gray-800">Total: ${total}</p> */}
        </div>
      </div>
      <div className="flex justify-between mt-4">
        {!isSummary ? (
          <Button onClick={handlePrev}>Previous</Button>
        ) : (
          <div></div>
        )}
        <Button type="primary" onClick={print}>
          Print Challan
        </Button>
      </div>
    </>
  );
};
