import { Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { Button, Col, DatePicker, Input, message, Select } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import Utils from "../../../utilites/Utils";
import ApiCalling from "../../../network/ApiCalling";
import { ITEM_IMAGE_URL } from "../../../utilites/Const";
import Placeholder from "../../../assests/icons/imag-holder.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrderItemsList } from "../../../redux/thunk/actions";
import { PlusOutlined } from "@ant-design/icons";

const AddProgramming = ({ onRefresh, refreshSummary, order }) => {
  const dispatch = useDispatch();
  const items = useSelector((state) => state?.programming?.itemList);
  // console.log("ITEMS", items);

  const manufacturerList = useSelector(
    (state) => state?.programming.manufacturerList
  );

  const sortedItems = [...items].sort(
    (a, b) => b.remainingQuantity - a.remainingQuantity
  );

  const options = sortedItems.map((item) => ({
    label: item.title,
    value: item?._id,
    quantity: item.quantity,
    remainingQuantity: item?.remainingQuantity,
    image: item?.image,
    MachineProductionPerDay: item?.MachineProductionPerDay,
  }));

  const mOption = manufacturerList?.map((m) => ({
    label: m?.name,
    value: m?._id,
    machine: m?.machine,
  }));
  // console.log("options", options);

  const handleFormSubmit = (values, resetForm) => {
    if (!values) return;
    const remaining = items?.find(
      (item) => item.value === values?.item
    )?.remainingQuantity;

    if (values.estimatedQuantity > remaining) {
      values.estimatedQuantity = remaining;
    }
    ApiCalling.apiCallPost("order-programming/create", values)
      .then((res) => {
        if (Utils.checkAPISuccessStatus(res)) {
          message.success(res?.data?.message);
          onRefresh(true);
          if (resetForm) {
            resetForm();
          }
        }
        dispatch(fetchOrderItemsList(order?._id));
      })
      .catch((err) => {
        console.log("ERORR", err);
      });
  };

  const calculateTotalProduction = (
    startDate,
    endDate,
    item,
    machine,
    items
  ) => {
    const daysDifference =
      startDate && endDate
        ? dayjs(endDate).diff(dayjs(startDate), "days") + 1
        : 0;

    const selectedItem = options.find((prodItem) => prodItem.value === item);

    const machineProductionPerDay = selectedItem?.MachineProductionPerDay || 0;
    const remainingQuantity = selectedItem?.remainingQuantity || 0;

    const totalProduction =
      machineProductionPerDay && daysDifference > 0
        ? machineProductionPerDay * daysDifference * machine
        : 0;

    const finalProduction =
      totalProduction > remainingQuantity ? remainingQuantity : totalProduction;

    return finalProduction > 0 ? finalProduction : "-";
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          item: "",
          manufacture: "",
          machine: "",
          startDate: null,
          endDate: null,
          estimatedQuantity: "",
        }}
        validationSchema={Yup.object().shape({
          item: Yup.string().required("Item is required"),
          manufacture: Yup.string().required("Manufacture is required"),
          machine: Yup.number()
            .required("Machine count is required")
            .test(
              "max-machine-count",
              `Machine value cannot exceed the maximum allowed.`,
              function (value) {
                const { manufacture } = this.parent;
                const selectedManufacturer = mOption.find(
                  (man) => man.value === manufacture
                );
                const maxMachineCount = selectedManufacturer?.machine || 0;
                if (value && value > maxMachineCount) {
                  return this.createError({
                    message: `Machine value cannot exceed ${maxMachineCount}.`,
                  });
                }
                return true;
              }
            ),
          startDate: Yup.date().required("Start date is required"),
          endDate: Yup.string().required("End date is required"),
          // totalPiece: Yup.string().required("Total pieces is required"),
        })}
        onSubmit={(values, { resetForm }) =>
          handleFormSubmit(values, resetForm)
        }
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <table className="table-auto w-full border-collapse ">
              <tbody className="w-full text-[14px]">
                <tr>
                  <td className=" min-w-32 max py-3 border-b-[1px] border-stroke-light">
                    <div className="flex place-items-center items-center gap-[10px] ">
                      <div>
                        {values.item && (
                          <>
                            {(() => {
                              const selectedItem = options.find(
                                (item) => item?.value === values?.item
                              );
                              const imageSrc = selectedItem?.image
                                ? ITEM_IMAGE_URL + selectedItem?.image
                                : Placeholder;

                              return (
                                <img
                                  src={imageSrc}
                                  className="object-cover w-[40px] rounded"
                                  alt="Selected Item"
                                />
                              );
                            })()}
                          </>
                        )}
                      </div>
                      <div className="flex  flex-col w-full">
                        <Select
                          className=" h-[40px] small-chevron"
                          placeholder="Select Item"
                          value={values.item || undefined}
                          onBlur={handleBlur}
                          name="item"
                          onChange={(value) => setFieldValue("item", value)}
                          notFoundContent="Create item of order"
                          optionLabelProp="children"
                        >
                          {options
                            .filter((r) => r.remainingQuantity > 0)
                            .map((option) => (
                              <Select.Option
                                key={option.value}
                                value={option.value}
                              >
                                <div className="flex justify-between">
                                  <span className="text-[14px]">
                                    {option.label}
                                  </span>
                                  <span className="text-secondary text-[10px] font-bold">
                                    {option.remainingQuantity}
                                  </span>
                                </div>
                              </Select.Option>
                            ))}
                        </Select>
                        <>
                          {errors.item && touched.item && (
                            <span className="error text-[10px] text-nowrap ">
                              {errors.item}
                            </span>
                          )}
                        </>
                      </div>
                    </div>
                  </td>
                  <td className="px-4 py-3   border-b-[1px] border-stroke-light">
                    <div className="flex flex-col">
                      <Select
                        placeholder="Select Manufacturer"
                        options={mOption}
                        className="w-[150px] h-[40px] small-chevron"
                        value={values.manufacture || undefined}
                        onBlur={handleBlur}
                        onChange={(value) =>
                          setFieldValue("manufacture", value)
                        }
                      />
                      <>
                        {errors.manufacture && touched.manufacture && (
                          <span className="error text-[10px] text-nowrap ">
                            {errors.manufacture}
                          </span>
                        )}
                      </>
                    </div>
                  </td>
                  <td className="px-4 py-3  border-b-[1px] border-stroke-light">
                    <div className="flex flex-col">
                      <Input
                        value={values.machine}
                        onBlur={handleBlur}
                        name="machine"
                        className="h-[40px] w-[100px] "
                        placeholder="Machine"
                        onChange={handleChange}
                      />
                      <>
                        {errors.machine && touched.machine && (
                          <span className="error text-[10px] text-nowrap ">
                            {errors.machine}
                          </span>
                        )}
                      </>
                    </div>
                  </td>
                  <td className="px-4 py-3  border-b-[1px] border-stroke-light">
                    <div className="flex flex-col">
                      <DatePicker
                        placeholder="Select Start Date"
                        disabledDate={(current) =>
                          current.isBefore(
                            moment(order?.orderDate).startOf("day")
                          ) ||
                          current.isAfter(
                            moment(order?.deliveryDate).endOf("day")
                          )
                        }
                        name="startDate"
                        value={values.startDate && dayjs(values.startDate)}
                        onChange={(date, dateString) => {
                          setFieldValue(
                            `startDate`,
                            date && moment(dateString, "YYYY-MM-DD")
                          );
                        }}
                        className="max-w-[125px] h-[40px]"
                        onBlur={handleBlur}
                      />
                      <>
                        {errors.startDate && touched.startDate && (
                          <span className="error text-[10px] text-nowrap ">
                            {errors.startDate}
                          </span>
                        )}
                      </>
                    </div>
                  </td>
                  <td className="px-4 py-3  border-b-[1px] border-stroke-light">
                    <div className="flex flex-col">
                      <DatePicker
                        placeholder="Select End Date"
                        disabledDate={(current) =>
                          current.isBefore(moment(order?.orderDate)) ||
                          current.isAfter(moment(order?.deliveryDate))
                        }
                        name="endDate"
                        value={values.endDate && dayjs(values?.endDate)}
                        onChange={(date, dateString) => {
                          setFieldValue(
                            `endDate`,
                            date ? moment(dateString, "YYYY-MM-DD") : null
                          );
                        }}
                        className="max-w-[125px] h-[40px]"
                        onBlur={handleBlur}
                      />
                      <>
                        {errors.endDate && touched.endDate && (
                          <span className="error text-[10px] text-nowrap ">
                            {errors.endDate}
                          </span>
                        )}
                      </>
                    </div>
                  </td>
                  <td className="px-4 py-3 border-b-[1px] border-stroke-light">
                    {values.startDate && values.endDate
                      ? dayjs(values.endDate).diff(
                          dayjs(values.startDate),
                          "days"
                        ) + 1
                      : "-"}
                  </td>

                  <td className="px-4 py-3 border-b-[1px] border-stroke-light">
                    <Input
                      value={values.estimatedQuantity}
                      onBlur={handleBlur}
                      name="estimatedQuantity"
                      className="h-[40px] w-[50px] min-w-[100px] border-0 ring-offset-0  outline-none active:outline-none focus:outline-none hover:outline-none"
                      readOnly
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />

                    {(() => {
                      const calculatedQuantity = calculateTotalProduction(
                        values.startDate,
                        values.endDate,
                        values.item,
                        values.machine,
                        items
                      );

                      if (
                        calculatedQuantity &&
                        calculatedQuantity !== values.estimatedQuantity
                      ) {
                        setFieldValue("estimatedQuantity", calculatedQuantity);
                      }
                    })()}
                  </td>

                  <td className="px-4 py-3  border-b-[1px] border-stroke-light">
                    <Button type="primary" className="mt-2" htmlType="submit">
                      Save
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        )}
      </Formik>
    </>
  );
};

export default AddProgramming;
