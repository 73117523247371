import React, { useEffect, useMemo, useState } from "react";
import { Table } from "antd";
import ApiCalling from "../../../../network/ApiCalling";
import Utils from "../../../../utilites/Utils";
import Main from "../../../../components/common/Main";
import { ITEM_IMAGE_URL } from "../../../../utilites/Const";
import Placeholder from "../../../../assests/icons/imag-holder.png";
import UpdateSubOrder from "../orders/updateSubOrder";
import { BiAddToQueue } from "react-icons/bi";
import TimeLine from "./timeLine";

const OrdersList = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [subOrder, setSubOrder] = useState({});
  const [timeline, setTimeline] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  useEffect(() => {
    getOrderList();
  }, [timeline]);

  const getOrderList = () => {
    setLoading(true);
    ApiCalling.apiCallGet(`manufacturer/orders`)
      .then((res) => {
        console.log("manufacturer/orders", res.data);
        setOrders(res?.data);
      })

      .catch((err) => console.error("ERROR", err))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpdateSubOrder = (subOrder) => {
    setOpenModal(true);
    setSubOrder(subOrder);
  };

  function isCloseModal() {
    setOpenModal(false);
  }

  const getSubOrderupdate = async (id) => {
    return ApiCalling.apiCallGet(`suborder-update/get/${id}`)
      .then((res) => {
        return res?.data || [];
      })
      .catch((err) => {
        console.log("ERR", err);
      });
  };

  const columns = [
    {
      title: "Order Id",
      dataIndex: "subOrderId",
      render: (_, order) => Utils.repraseManuOrderId(order?.subOrderId),
    },
    {
      title: "Item",
      render: (_, order) => (
        <div className="flex place-items-center gap-[10px]">
          <img
            className="object-cover w-[50px]   "
            src={
              order?.item?.image
                ? ITEM_IMAGE_URL + order?.item?.image
                : Placeholder
            }
          />
          {order?.item?.title}
        </div>
      ),
    },
    {
      title: "Machine",
      dataIndex: "machine",
    },
    {
      title: "Order Date",
      dataIndex: "startDate",

      render: (date) => Utils.formateDateForRead(date),
    },
    {
      title: "Delivery Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (endDate) => Utils.formateDateForRead(endDate),
    },
    {
      title: "Quantity",
      dataIndex: "remaining",
      key: "estimatedQuantity",

      render: (_, q) => `${q.remaining} / ${q.estimatedQuantity}`,
    },
    {
      title: "Status",
      dataIndex: "subOrderStatus",
      key: "subOrderStatus",
      render: (_, status) => Utils.checkMOrderStatus(status?.subOrderStatus),
    },
  ];

  const handleExpand = async (expanded, record) => {
    // console.log("expand log", expanded, record);

    if (expanded) {
      try {
        const updates = await getSubOrderupdate(record?._id);
        if (updates && updates.length > 0) {
          setTimeline((prev) => ({
            ...prev,
            [record._id]: updates,
          }));
        } else {
          console.warn("No updates found for record:", record._id);
          setTimeline((prev) => ({
            ...prev,
            [record._id]: [],
          }));
        }
        setExpandedRowKeys([record._id]);
      } catch (err) {
        console.error("Error in handleExpand:", err);
      }
    } else {
      setExpandedRowKeys([]);
    }
  };

  return (
    <Main>
      <div className="flex items-start justify-between">
        <h1 className="text-extralarge font-bold pb-[30px]">Orders</h1>
      </div>
      <Table
        rowHoverable={false}
        loading={loading}
        columns={columns}
        dataSource={orders?.map((item) => ({
          ...item,
          key: item._id,
        }))}
        className="bg-white rounded-[6px] cursor-pointer"
        pagination={true}
        expandable={{
          expandRowByClick: true,
          expandIconColumnIndex: 7,
          onExpand: handleExpand,

          expandedRowRender: (subOrder) => {
            const updates = timeline[subOrder?._id];
            const pendingQuantity =
              subOrder.estimatedQuantity -
              updates?.reduce((acc, itr) => acc + itr.quantity, 0);
            return updates && updates.length > 0 ? (
              <TimeLine
                key={updates?._id}
                updates={updates}
                pendingQuantity={pendingQuantity}
                subOrder={subOrder}
                handleUpdateSubOrder={handleUpdateSubOrder}
                handleExpand={handleExpand}
              />
            ) : (
              <div className="flex items-center gap-3">
                <p className="mb-0  text-secondary font-semibold">
                  No updates available for this order. Add by clicking here.
                </p>

                <BiAddToQueue
                  className="text-stroke "
                  role="button"
                  onClick={() => handleUpdateSubOrder(subOrder)}
                  size={18}
                />
              </div>
            );
          },
          expandedRowKeys,
        }}
      />

      <UpdateSubOrder
        isModalOpen={openModal}
        isCloseModal={isCloseModal}
        subOrder={subOrder}
        handleExpand={handleExpand}
      />
    </Main>
  );
};

export default OrdersList;
