import React, { useEffect, useState } from "react";
import { Button, Empty, Space, Table, Tag } from "antd";
import { FiEdit } from "react-icons/fi";
import { BiAlignJustify, BiGridAlt, BiPlus } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Main from "../../components/common/Main";
import Utils from "../../utilites/Utils";
import ApiCalling from "../../network/ApiCalling";
import Suffle from "../../assests/icons/suffle.svg";
import { MdAddChart } from "react-icons/md";
import {
  BarChartOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";
import { IoPartlySunny } from "react-icons/io5";

const OrdersList = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    getOrderList();
  }, []);

  const getOrderList = () => {
    setLoading(true);
    ApiCalling.apiCallGet("order/get")
      .then((res) => {
        // console.log("list",res.data);
        setLoading(false);
        if (Utils.checkAPISuccessStatus(res)) {
          setOrders(
            res?.data?.map((order) => ({
              ...order,
              key: order?._id,
              orderDate: order?.orderDate,
              deliveryDate: order?.deliveryDate,
              orderStatus: order?.orderStatus,
            }))
          );
        }
      })
      .catch((err) => console.error("ERROR", err));
  };

  const handleCreateOrderItem = (order) => {
    const isItem = true;
    navigate(`/create-order`, {
      state: { isItem, order },
    });
  };

  const handleEdit = (item) => {
    navigate(`/create-order`, {
      state: item,
    });
  };

  const handleProgramming = (order) => {
    const isPrograming = true;
    navigate(`/create-order`, {
      state: {
        isPrograming,
        order,
      },
    });
  };

  const handlePlan = (order) => {
    const isPlan = true;
    navigate(`/order-plan`, {
      state: {
        order,
        isPlan,
      },
    });
  };

  const handleSort = (sortBy) => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);

    setLoading(true);
    ApiCalling.apiCallGet(
      `order/get?sortBy=${sortBy}&sortOrder=${newSortOrder}`
    )
      .then((res) => {
        setLoading(false);
        if (Utils.checkAPISuccessStatus(res)) {
          setOrders(
            res?.data?.map((order) => ({
              ...order,
              key: order._id,
              orderDate: Utils.formateDateForRead(order.orderDate),
              deliveryDate: Utils.formateDateForRead(order.deliveryDate),
              orderStatus: order?.orderStatus,
            }))
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error("Error during sorting:", err);
      });
  };

  const columns = [
    {
      title: "Order Id",
      dataIndex: "name",
      key: "name",
      render: (text, record) => <a>{Utils.repraseOrderId(record?.orderId)}</a>,
    },
    {
      title: "Vendor",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Order Date",
      dataIndex: "orderDate",
      key: "orderDate",
      render: (text, record) => (
        <a>{Utils.formateDateForRead(record?.orderDate)}</a>
      ),
    },

    {
      title: (
        <span>
          Delivery Date
          {sortOrder === "asc" ? (
            <SortDescendingOutlined
              style={{ cursor: "pointer", marginLeft: "8px" }}
              onClick={() => handleSort("deliveryDate")}
            />
          ) : (
            <SortAscendingOutlined
              style={{ cursor: "pointer", marginLeft: "8px" }}
              onClick={() => handleSort("deliveryDate")}
            />
          )}
        </span>
      ),
      dataIndex: "deliveryDate",
      key: "deliveryDate",
      render: (_, order) => (
        <a>{Utils.formateDateForRead(order?.deliveryDate)}</a>
      ),
    },
    {
      title: "Status",
      dataIndex: "orderStatus",
      key: "orderStatus",
      render: (status) => Utils.checkStatus(status),
    },
    {
      key: "action",
      render: (_, order) => (
        <div className="flex gap-[20px]">
          <Space size="middle">
            <BarChartOutlined
              className="text-stroke"
              onClick={() => handleProgramming(order)}
            />
          </Space>
          <Space size="middle">
            <img
              src={Suffle}
              sizes="24"
              role="button"
              onClick={() => handleCreateOrderItem(order)}
            />
          </Space>
          <Space size="middle">
            <FiEdit
              role="button"
              className="text-stroke"
              onClick={() => handleEdit(order)}
            />
          </Space>
          <Space size="middle">
            <MdAddChart
              role="button"
              className="text-stroke"
              onClick={() => handlePlan(order)}
            />
          </Space>
        </div>
      ),
    },
  ];
  return (
    <Main>
      <div className="flex items-start justify-between">
        <h1 className="text-extralarge font-bold pb-[30px]">Orders</h1>
        <Button
          type="primary"
          className="rounded h-[40px] font-sans"
          icon={<BiPlus size={20} className="mx-0 space-x-0 " />}
          onClick={() => navigate("/create-order")}
        >
          Create Order
        </Button>
      </div>

      <Table
        loading={loading}
        headerbg
        columns={columns}
        dataSource={orders.map((item) => ({
          ...item,
          key: item._id,
        }))}
        locale={{
          emptyText: <Empty description="You don't have order." />,
        }}
        className="bg-white rounded-[6px] "
        pagination={true}
      />
    </Main>
  );
};

export default OrdersList;
