import React, { useState } from "react";
import { Button, Flex, Input, message, Space } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";
import FormItem from "antd/es/form/FormItem";
import Utli from "../../../utilites/Utils";
import ApiCalling from "../../../network/ApiCalling";
import { Link, useNavigate } from "react-router-dom";
import Utils from "../../../utilites/Utils";

const ManufacturerLogin = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleLogin = async (values, resetForm) => {
    setLoading(true);
    try {
      const res = await ApiCalling.apiCallPost("manufacturer/login", values);
      if (Utils.checkAPISuccessStatus(res)) {
        // console.log("manufacture login", res);
        Utils.saveManufactureToken(res?.data?.token);
        Utils.saveToken(res.data.token);
        Utils.saveManufactureUser(res?.data?.data);
        message.success(res?.data?.message);
        resetForm();
        setTimeout(() => {
          navigate("/manufacturer-dashboard");
        }, 1000);
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <h3 className="text-[26px] font-bold py-3">Sign In</h3>
      <Formik
        initialValues={{
          mobile: "",
          password: "",
        }}
        validationSchema={Yup.object().shape({
          mobile: Yup.string().max(10).required("Phone is required"),
          password: Yup.string().max(255).required("Password is required"),
        })}
        onSubmit={(values, { resetForm }) => handleLogin(values, resetForm)}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Flex
              vertical
              gap={errors.email && errors.password ? 8 : 25}
              style={{ lineHeight: 0 }}
            >
              <Input
                placeholder="Phone"
                variant="filled"
                name="mobile"
                value={values.mobile}
                // disabled={loading}
                readOnly={loading}
                onBlur={handleBlur}
                onChange={handleChange}
                size="large"
                className="w-full h-[45px] text-[14px] font-sans font-medium mb-0 bg-input rounded-[6px] focus:outline-none focus:ring-1 focus:ring-stroke-light "
              />
              {touched.mobile && errors.mobile && (
                <span
                  style={{ lineHeight: 0 }}
                  className="pb-0 mb-0  font-sans px-[6px] text-[12px]  text-red-800"
                >
                  {errors.mobile}
                </span>
              )}

              <Input.Password
                placeholder="Password"
                variant="filled"
                type="password"
                name="password"
                // size="large"
                onBlur={handleBlur}
                // disabled={loading}
                visibilityToggle={{
                  visible: passwordVisible,
                  onVisibleChange: setPasswordVisible,
                }}
                className="w-full h-[45px] text-[14px] font-sans font-medium  bg-input rounded-[6px] focus:outline-none focus:ring-1 focus:ring-stroke-light"
                readOnly={loading}
                value={values.password}
                onChange={(e) => {
                  handleChange(e);
                }}
              />

              {touched.password && errors.password && (
                <span className="pb-0 mb-0  font-sans px-[6px] text-[12px]  text-red-800">
                  {errors.password}
                </span>
              )}
              <Button
                loading={loading}
                // disabled={isSubmitting}
                type="primary"
                className="bg-primary font-sans h-[45px] mb-10"
                htmlType="submit"
              >
                Sign In
              </Button>
            </Flex>
          </form>
        )}
      </Formik>
    </>
  );
};
export default ManufacturerLogin;
