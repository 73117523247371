import React, { useState } from "react";
import ModalProvider from "../../../components/common/ModalProvider";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
} from "antd";
import { FieldArray, Formik } from "formik";
import dayjs from "dayjs";
import * as Yup from "yup";
import moment from "moment";
import { PlusOutlined } from "@ant-design/icons";
import ApiCalling from "../../../network/ApiCalling";
import Utils from "../../../utilites/Utils";
import { fetchOrderSummary } from "../../../redux/thunk/actions";
import { useDispatch } from "react-redux";

const Dispatch = ({ order, manufacturer, isModalOpen, isCloseModal }) => {
  // console.log("manufacturer data", manufacturer);
  const dispatch = useDispatch();

  const handleAddField = (setFieldValue, values) => {
    if (!values) return;
    const allFiledsFilled = values.rows.every(
      (row) => row.material && row.quantity
    );
    if (!allFiledsFilled) {
      message.warning("Please fill in all fields before adding a new row.");
      return;
    }

    const availableOptions = getFilteredMaterialOptions(
      values.rows.length,
      values
    );
    if (availableOptions.length === 0) {
      message.warning("No more materials available to add.");
      return;
    }
    if (values?.rows?.length)
      setFieldValue("rows", [
        ...(values.rows || []),
        {
          material: "",
          quantity: "",
          unit: "",
          total: "",
          totalDispatched: "",
          usedQuantity: "",
          remainingQuantity: "",
          required: "",
        },
      ]);
  };

  const handleItemSubmit = (values, resetForm) => {
    // console.log("value", values);

    values.dispatchItems = values.rows;
    delete values.rows;

    ApiCalling.apiCallPost("dispatch-material/create", values)
      .then((res) => {
        if (Utils.checkAPISuccessStatus(res)) {
          message.success(res?.data?.message);
          resetForm();
          isCloseModal();
          dispatch(fetchOrderSummary(order?._id));
        }
      })
      .catch((err) => {
        console.log("ERR", err);
      });
  };
  const handleChangeQuantity = (index, value, setFieldValue, values) => {
    const selectedMaterial = mOption.find((mat) => mat?.value === value);
    setFieldValue(`rows[${index}].quantity`, value);

    const total = values?.rows[index]?.total;
    const remainingQuantity = values?.rows[index]?.remainingQuantity;
    const requiredQty = total - remainingQuantity;

    if (Number(value) > requiredQty) {
      // setRequireMaterial(
      //   `Required quantity is  ${requiredQty} ${values.rows[index]?.unit}`
      // );

      message.warning(
        `quantity exceeds total needed by ${total - remainingQuantity} ${
          values.rows[index]?.unit
        }`
      );
    }
  };

  const handleSelectChange = (index, value, setFieldValue, values) => {
    const selectedMaterial = mOption.find((mat) => mat?.value === value);
    // console.log("sss", selectedMaterial);

    if (selectedMaterial) {
      setFieldValue(`rows[${index}].material`, value);
      setFieldValue(`rows[${index}].unit`, selectedMaterial?.unit);
      setFieldValue(
        `rows[${index}].remainingQuantity`,
        selectedMaterial?.remainingQuantity
      );
      setFieldValue(`rows[${index}].total`, selectedMaterial?.total);
      setFieldValue(
        `rows[${index}].totalDispatched`,
        selectedMaterial?.totalDispatched
      );
      setFieldValue(
        `rows[${index}].usedQuantity`,
        selectedMaterial?.usedQuantity
      );
      setFieldValue(`rows[${index}].required`, selectedMaterial?.required);
    }
  };

  if (!manufacturer?.rm) {
    console.error("manufacturer.rm is not defined");
    return;
  }

  const materialList = Object.keys(manufacturer?.rm || {}).map((key) => {
    // const value = manufacturer?.rm[key];
    // console.log("value", value);

    const data = manufacturer?.rm[key];
    if (!data) {
      console.error(`Data for key ${key} is not defined`);
    }
    return data;
  });
  // console.log("materialList", materialList);

  const mOption = materialList?.map((m) => ({
    label: m?.data?.title,
    value: m?.data?._id,
    unit: m?.data.unit,
    remainingQuantity: m?.remainingQuantity,
    total: m?.total,
    totalDispatched: m?.totalDispatched,
    usedQuantity: m?.usedQuantity,
  }));
  console.log("mOption", mOption);

  const getFilteredMaterialOptions = (index, values) => {
    const selectedValues = values.rows
      .filter((_, i) => i !== index)
      .map((row) => row?.material);
    return mOption.filter((mat) => !selectedValues.includes(mat?.value));
  };

  const renderLable = (label, value, unit) => {
    if (value === "-") {
      return (
        <p className="text-gray-500 text-small text-[12px] mb-[2px]">
          {`${label} ${value}  `}
        </p>
      );
    }
    return value ? (
      <p className="text-gray-500 text-small text-[12px] mb-[2px]">
        {`${label} ${value} ${unit}`}
      </p>
    ) : (
      ""
    );
  };

  function requiredQty(label, total, remainingQuantity, unit, value) {
    if (total && remainingQuantity)
      return (
        <p className="text-gray-500 text-small text-[12px] mb-[2px]">
          {`${label} ${total - remainingQuantity} ${unit} `}
        </p>
      );
  }
  return (
    <>
      <ModalProvider
        isModalOpen={isModalOpen}
        OnClose={() => {
          isCloseModal();
        }}
        width={600}
      >
        <Formik
          enableReinitialize
          initialValues={{
            manufacture: manufacturer?.manufacturer?.id,
            order: order?._id,
            dispatchDate: moment(),
            rows: [
              {
                material: "",
                quantity: "",
              },
            ],
          }}
          validationSchema={Yup.object().shape({
            dispatchDate: Yup.date().required("Date is required"),
            rows: Yup.array().of(
              Yup.object().shape({
                material: Yup.string().required("Material is required"),
                quantity: Yup.number()
                  .typeError("Quantity must be a number")
                  .required("Quantity is required"),
              })
            ),
          })}
          onSubmit={(values, { resetForm }) =>
            handleItemSubmit(values, resetForm)
          }
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
          }) => {
            return (
              <form noValidate onSubmit={handleSubmit} className="">
                <Row gutter={20} className="pl-[10px] pr-[10px]">
                  <h4 className="text-[20px] font-bold   pl-[5px] mb-[20px]">
                    {`Dispatch to ${manufacturer?.manufacturer?.name}`}
                  </h4>
                  <Col span={24} className="h-[100px]">
                    <Form.Item
                      layout="vertical"
                      className="text-nero text-2xl"
                      label="Date"
                    >
                      <DatePicker
                        name="dispatchDate"
                        placeholder="Select Date"
                        // disabledDate={(current) =>
                        //   current.isBefore(moment(subOrder?.startDate)) ||
                        //   current.isAfter(moment(subOrder?.endDate))
                        // }
                        className="h-[45px] w-full"
                        value={
                          values.dispatchDate
                            ? dayjs(values.dispatchDate)
                            : null
                        }
                        onChange={(date, dateString) =>
                          setFieldValue(
                            "dispatchDate",
                            date ? moment(dateString, "YYYY-MM-DD") : null
                          )
                        }
                        onBlur={handleBlur}
                      />
                      {errors.dispatchDate && (
                        <span className="text-red-500 text-[13px]  text-left">
                          {errors.dispatchDate}
                        </span>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <FieldArray
                      name="rows"
                      render={() =>
                        values?.rows?.map((_, index) => (
                          <div className="flex gap-[20px] mb-[10px]">
                            <div className="w-full">
                              <Select
                                options={getFilteredMaterialOptions(
                                  index,
                                  values
                                )}
                                placeholder="Select material"
                                className="w-full h-[40px] small-chevron"
                                value={values.rows[index]?.material || null}
                                onBlur={handleBlur}
                                onChange={(value) => {
                                  handleSelectChange(
                                    index,
                                    value,
                                    setFieldValue,
                                    values
                                  );
                                }}
                              />
                              {touched.rows?.[index]?.material &&
                                errors.rows?.[index]?.material && (
                                  <span className="text-red-500 text-[13px] text-left">
                                    {errors.rows[index].material}
                                  </span>
                                )}
                              <div className="p-2">
                                {renderLable(
                                  "Total needed",
                                  values.rows[index]?.total,
                                  values.rows[index]?.unit
                                )}
                                {renderLable(
                                  "Total dispatched",
                                  values.rows[index]?.totalDispatched,
                                  values.rows[index]?.unit
                                )}
                                {renderLable(
                                  "Total used",
                                  values.rows[index]?.usedQuantity,
                                  values.rows[index]?.unit
                                )}
                                {renderLable(
                                  "Remaining dispatch",
                                  values.rows[index]?.remainingQuantity,
                                  values.rows[index]?.unit
                                )}

                                {requiredQty(
                                  "Required quantity",
                                  values.rows[index]?.total,
                                  values.rows[index]?.remainingQuantity,
                                  values.rows[index]?.unit
                                )}
                              </div>
                            </div>

                            <div className="relative w-full h-[40px]">
                              <Input
                                value={values.rows[index].quantity}
                                onBlur={handleBlur}
                                placeholder="Quantity"
                                name="quantity"
                                className="w-full h-full pr-10 pl-4 border rounded outline-0  "
                                onChange={(e) =>
                                  handleChangeQuantity(
                                    index,
                                    e.target.value,
                                    setFieldValue,
                                    values
                                  )
                                }
                              />
                              <span className="absolute right-5 top-1/2 -translate-y-1/2 text-gray-500 text-sm">
                                {values.rows[index]?.unit || ""}
                              </span>
                              <span className="  text-[12px] text-green-600">
                                {/* {values.rows[index]?.required || ""} */}
                                {/* {requireMaterial ? requireMaterial : " "} */}
                              </span>

                              {touched.rows?.[index]?.quantity &&
                                errors.rows?.[index]?.quantity && (
                                  <span className="text-red-500 text-[13px] text-left">
                                    {errors.rows[index].quantity}
                                  </span>
                                )}
                            </div>
                          </div>
                        ))
                      }
                    />
                  </Col>

                  <Col
                    className="flex justify-end items-end flex-col"
                    span={24}
                  >
                    <Button
                      type="link"
                      icon={<PlusOutlined />}
                      onClick={() => handleAddField(setFieldValue, values)}
                    >
                      Add Field
                    </Button>
                    <Button
                      className="w-full mt-[10px]"
                      type="primary"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </form>
            );
          }}
        </Formik>
      </ModalProvider>
    </>
  );
};

export default Dispatch;
