import { Button, Card, message, Popconfirm, Table } from "antd";
import moment from "moment";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Utils from "../../utilites/Utils";
import BreadcrumbComponent from "../../components/breadcrumb/BreadcrumbComponent ";
import Main from "../../components/common/Main";
import { ITEM_IMAGE_URL } from "../../utilites/Const";
import Placeholder from "../../assests/icons/imag-holder.png";
import ApiCalling from "../../network/ApiCalling";
import { CheckOutlined } from "@ant-design/icons";

const ChallanSummary = () => {
  const location = useLocation();
  const navigate = useNavigate();
  console.log("locaiton", location);
  const { isSummary, challan } = location.state;
  // console.log("ss", isSummary, challan);

  const breadcrumbItems = [
    { label: "Challan", path: "/challan" },
    {
      label: "Challan Summary",
    },
  ];

  const columns = [
    {
      title: "Suborder Id",
      dataIndex: ["subOrder", "subOrderId"],
      key: "subOrderId",
      render: (subOrderId) => Utils.repraseManuOrderId(subOrderId),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => Utils.formateDateForRead(date),
    },
    {
      title: "Item Title",
      dataIndex: ["subOrder", "item", "title"],
      key: "title",
    },
    {
      title: "Image",
      dataIndex: ["subOrder", "item", "image"],
      key: "image",
      render: (image) => (
        <img width={50} src={image ? ITEM_IMAGE_URL + image : Placeholder} />
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
  ];

  const handleApproveChallan = (challan) => {
    ApiCalling.apiCallPut(`order-chalaan/admin/approve/${challan?._id}`)
      .then((res) => {
        if (Utils.checkAPISuccessStatus(res)) {
          console.log("res approve", res);
          message.success(res?.data?.message);
          navigate("/challan");
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Main>
        <div className="flex items-start justify-between ">
          <div className="flex flex-col gap-0 mb-[20px]">
            <h1 className="text-extralarge font-bold">{"Challan Summary"}</h1>
            <BreadcrumbComponent items={breadcrumbItems} />
          </div>
        </div>
        <Card title={`Challan Summary`} bordered={false} className="mb-[20px]">
          <div className="flex items-center justify-between">
            <div>
              <p>
                <strong>Challan No:</strong> {challan?.chalaanNo}
              </p>
              <p>
                <strong>Challan Date:</strong> {Utils.formateDateForRead(challan?.date)}
              </p>
              <p>
                <strong>Status:</strong> {Utils.challanStatus(challan?.status)}
              </p>
            </div>
            <div>
              {challan?.status === 0 && (
                <Popconfirm
                  placement="topRight"
                  icon={<CheckOutlined />}
                  title="Approve the Challan"
                  className="text-sm"
                  description="Are you sure to approve this Challan?"
                  onConfirm={() => handleApproveChallan(challan)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button>Approve Challan</Button>
                </Popconfirm>
              )}
            </div>
          </div>
        </Card>
        <Table
          size="middle"
          rootClassName=" pb-[50px] bg-white "
          dataSource={challan?.subOrderUpdates}
          columns={columns}
          rowKey={(record) => record._id}
          //   bordered
          pagination={false}
        />
      </Main>
    </>
  );
};

export default ChallanSummary;
