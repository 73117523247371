import React, { useRef, useState } from "react";
import { Button, Modal } from "antd";

const ModalProvider = ({
  width = 1000,
  height = "",
  isModalOpen,
  onOk,
  OnClose,
  children,
  className,
  loading = "",
}) => {
  function handleClose() {
    OnClose();
  }

  return (
    <>
      <Modal
        width={width || 1000}
        // title="Vertically centered modal dialog"
        height={height || ""}
        centered
        open={isModalOpen}
        onOk={handleClose}
        onCancel={handleClose}
        footer={null}
        className={className || ""}
        loading={loading}
      >
        {children}
      </Modal>
    </>
  );
};

export default ModalProvider;
