// src/components/Sidebar.js
import React, { useEffect, useState } from "react";
import {
  ContainerOutlined,
  DesktopOutlined,
  DashboardOutlined,
  ShoppingCartOutlined,
  WechatWorkFilled,
  WechatWorkOutlined,
} from "@ant-design/icons";
import { Button, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";

import { LuList } from "react-icons/lu";

const items = [
  {
    key: "1",
    icon: <DashboardOutlined />,
    label: (
      <Link to="/dashboard" className="  no-underline">
        Dashboard
      </Link>
    ),
  },
  {
    key: "2",
    icon: <ContainerOutlined />,
    label: (
      <Link to="/orders" className="  no-underline">
        Orders
      </Link>
    ),
  },
  {
    key: "3",
    icon: <ShoppingCartOutlined />,
    label: (
      <Link to="/vendor" className="  no-underline">
        Vendor
      </Link>
    ),
  },
  {
    key: "4",
    icon: <WechatWorkOutlined />,
    label: (
      <Link to="/manufacturer" className=" no-underline">
        Manufacturer
      </Link>
    ),
  },
  {
    key: "5",
    icon: <ShoppingCartOutlined />,
    label: (
      <Link to="/raw-material" className=" no-underline">
        Raw Material
      </Link>
    ),
  },
  {
    key: "6",
    icon: <LuList />,
    label: (
      <Link to="/challan" className=" no-underline">
        Challan
      </Link>
    ),
  },
];

const Logo = () => {
  return (
    <div className="h-[70px] flex items-center justify-start ">
      <h4 className="font-sans font-extrabold mx-[30px] text-large"></h4>
    </div>
  );
};

const Sidebar = ({ collapsed }) => {
  const location = useLocation();

  const routeToKey = {
    "/dashboard": "1",
    "/orders": "2",
    "/vendor": "3",
    "/manufacturer": "4",
    "/raw-material": "5",
    "/challan": "6",
  };
  const [selectedKey, setSelectedKey] = useState(
    routeToKey[location.pathname] || "1"
  );
  // console.log(location);

  useEffect(() => {
    const key = routeToKey[location.pathname];
    if (key) {
      setSelectedKey(key);
    }
  }, [location.pathname]);

  return (
    <div
      className={`bg-white h-screen border-r-[1px]  border-stroke-light ${
        collapsed ? "w-[80px]" : "w-[260px]"
      } transition-width duration-300`}
    >
      <Logo />

      <Menu
        inlineCollapsed={collapsed}
        selectedKeys={[selectedKey]}
        items={items}
        className={`custom-menu w-full ant-menu-vertical ant-menu-light mx-[15px]`}
      />
    </div>
  );
};

export default Sidebar;
