import React, { useEffect, useState } from "react";
import ModalProvider from "../../../components/common/ModalProvider";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Typography,
} from "antd";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import ApiCalling from "../../../network/ApiCalling";
import Utils from "../../../utilites/Utils";
import { IoTrashBin, IoTrashBinOutline } from "react-icons/io5";
const { Text } = Typography;

const RowMaterialContainer = ({ isModalOpen, OnClose, item }) => {
  // console.log("item value", item);

  const [material, setMaterial] = useState([]);
  const [itemRawMaterials, setItemRawMaterials] = useState([]);
  const [units, setUnits] = useState({});

  const handleAddField = (setFieldValue, values) => {
    const selectedValue = values.rows.map((row) => row.name);
    const unselectedMaterials = material.filter(
      (mat) => !selectedValue.includes(mat.value)
    );

    if (unselectedMaterials.length === 0) {
      message.warning("All available materials have been selected.");
      return;
    }
    const allFiledsFilled = values.rows.every(
      (row) => row.name && row.perPiece && row.totalPiece
    );
    if (!allFiledsFilled) {
      message.warning("Please fill in all fields before adding a new row.");
      return;
    }
    if (values?.rows?.length)
      for (const v of values.rows) v.totalPiece = v.perPiece * item.quantity;
    setFieldValue("rows", [
      ...(values.rows || []),
      {
        name: "",
        perPiece: "",
        totalPiece: "",
        item: item?._id,
        delete: false,
      },
    ]);
  };

  const handleChangePerPiece = (index, value, setFieldValue, values) => {
    setFieldValue(`rows[${index}].perPiece`, value);
    const totalPiece = value * item.quantity;
    setFieldValue(`rows[${index}].totalPiece`, totalPiece);
    // console.log("#rrrrr", value, item.quantity, totalPiece);
  };

  const handleFormSubmit = (values, resetForm) => {
    const body =
      values?.rows?.map((v) => ({ ...v, rawMaterial: v.name })) || [];

    ApiCalling.apiCallPost("item/material/change", body)
      .then((res) => {
        // console.log("***", res);
        if (Utils.checkAPISuccessStatus(res)) {
          message.success(res?.data?.message);
          OnClose();
        }
      })
      .catch((err) => {
        console.log("ERORR", err);
      });
  };

  useEffect(() => {
    getRawMaterialList();
  }, []);
  const getRawMaterialList = () => {
    ApiCalling.apiCallGet("raw-material/get")
      .then((res) => {
        // console.log("RAW list", res.data);
        if (Utils.checkAPISuccessStatus(res)) {
          setMaterial(
            res?.data?.map((material) => ({
              label: material.title,
              value: material._id,
              unit: material.unit,
            }))
          );
        }
      })
      .catch((err) => console.error("ERROR", err));
  };
  const getItemRawMaterialList = () => {
    ApiCalling.apiCallGet("item/material/get/" + item?._id)
      .then((res) => {
        if (Utils.checkAPISuccessStatus(res)) {
          const result = res?.data?.map((d) => ({
            ...d,
            name: d.rawMaterial,
            perPiece: d.perPiece,
            totalPiece: d.perPiece * item.quantity,
            delete: false,
          }));
          setItemRawMaterials(result);
        }
      })
      .catch((err) => console.error("ERROR", err));
  };

  useEffect(() => {
    if (item?._id) {
      getItemRawMaterialList();
    }
  }, [item]);

  useEffect(() => {
    const initialUnit = {};

    itemRawMaterials.forEach((row, index) => {
      const selectMaterial = material.find((mat) => mat.value === row.name);
      if (selectMaterial) {
        initialUnit[index] = selectMaterial.unit;
      }
      setUnits(initialUnit);
    });
  }, [itemRawMaterials, material]);

  const handleSelectChange = (index, value, setFieldValue, values) => {
    const selectedMaterial = material.find((mat) => mat.value === value);
    if (selectedMaterial) {
      setFieldValue(`rows[${index}].name`, value);
      setUnits((prevUnits) => ({
        ...prevUnits,
        [index]: selectedMaterial.unit,
      }));
    }
  };

  const getFilteredMaterialOptions = (index, values) => {
    const selectedValues = values.rows
      .filter((_, i) => i !== index)
      .map((row) => row.name);
    return material.filter((mat) => !selectedValues.includes(mat.value));
  };

  const handleDeleteRow = (index, values, setFieldValue) => {
    // console.log("index, setFieldValue", index);
    const updatedRows = values.rows.map((row, idx) =>
      idx === index ? { ...row, delete: true } : row
    );
    setFieldValue("rows", updatedRows);
    // console.log("updatedRows", updatedRows);
  };

  // const handleEditRow = (index, values, setFieldValue) => {
  //   console.log("edit row", index);
  // };
  return (
    <ModalProvider isModalOpen={isModalOpen} OnClose={OnClose}>
      <div className=" mb-[10px] pr-[30px] ">
        <Text className="font-bold text-[24px]">
          Material needed for 1 "{item?.title}" {itemRawMaterials.length} piece
        </Text>
      </div>

      <Formik
        enableReinitialize
        initialValues={{
          rows: itemRawMaterials.length
            ? itemRawMaterials
            : [
                {
                  name: "",
                  perPiece: "",
                  totalPiece: "",
                  item: item?._id,
                  delete: false,
                },
              ],
        }}
        validationSchema={Yup.object().shape({
          rows: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().required("Name is required"),
              perPiece: Yup.string().required("Piece is required"),
              // totalPiece: Yup.string().required("Total pieces is required"),
            })
          ),
        })}
        onSubmit={(values, { resetForm }) =>
          handleFormSubmit(values, resetForm)
        }
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <table className="table-auto w-full border-collapse border border-gray-300 ">
              <thead>
                <tr>
                  <th className="border text-nero font-medium px-4 py-2">
                    Material
                  </th>
                  <th className="border text-nero font-medium px-4 py-2">
                    Raw Material
                  </th>
                  <th className="border text-nero font-medium px-4 py-2">
                    Per Piece qty
                  </th>
                  <th className="border text-nero font-medium px-4 py-2">{`Estimated qty for ${item?.quantity}  piece`}</th>
                </tr>
              </thead>
              <tbody>
                <FieldArray
                  name="rows"
                  render={() =>
                    values.rows
                      .filter((row) => !row.delete)
                      .map((_, index) => (
                        <tr key={index}>
                          {itemRawMaterials?.length > 0 && (
                            <td className="border-b-[1px] flex px-3 py-3">
                              <Button
                                className="text-stroke"
                                type="text"
                                onClick={() =>
                                  handleDeleteRow(index, values, setFieldValue)
                                }
                              >
                                <IoTrashBinOutline
                                  className=" text-[20px]"
                                  role="button"
                                />
                              </Button>
                            </td>
                          )}
                          <td className="border w-[30%] px-4 py-2">
                            <Select
                              options={getFilteredMaterialOptions(
                                index,
                                values
                              )}
                              className="w-full h-[40px] small-chevron"
                              value={values.rows[index].name}
                              onBlur={handleBlur}
                              onChange={(value) =>
                                handleSelectChange(
                                  index,
                                  value,
                                  setFieldValue,
                                  values
                                )
                              }
                            />
                            {touched.rows?.[index]?.name &&
                              errors.rows?.[index]?.name && (
                                <Form.Item className="text-red-500 text-left">
                                  {errors.rows[index].name}
                                </Form.Item>
                              )}
                          </td>
                          <td className="border px-4 w-[30%] py-2">
                            <div className="flex gap-[10px] items-center">
                              <Input
                                value={values.rows[index].perPiece}
                                onBlur={handleBlur}
                                className="w-full h-[40px]"
                                onChange={(e) =>
                                  handleChangePerPiece(
                                    index,
                                    e.target.value,
                                    setFieldValue,
                                    values
                                  )
                                }
                              />
                              <p className=" py-2 mb-0">{units[index] || ""}</p>
                            </div>
                            {touched.rows?.[index]?.perPiece &&
                              errors.rows?.[index]?.perPiece && (
                                <Form.Item className="text-red-500">
                                  {errors.rows[index].perPiece}
                                </Form.Item>
                              )}
                          </td>

                          <td className="border px-4 py-2 text-stroke">
                            {values.rows[index].totalPiece} {units[index] || ""}
                          </td>
                        </tr>
                      ))
                  }
                />
              </tbody>
            </table>
            <div className="  flex  my-[20px] justify-end">
              <Button
                type="link"
                icon={<PlusOutlined />}
                onClick={() => handleAddField(setFieldValue, values)}
              >
                Add Field
              </Button>
            </div>
            <div className="flex   justify-end items-center my-[20px]">
              <div className="flex items-center  gap-2">
                <Button
                  onClick={() => {
                    OnClose();
                  }}
                >
                  Cancel
                </Button>

                <Button
                  type="primary"
                  htmlType="submit"
                  // loading={isSubmitting}
                  className="  "
                >
                  Save
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </ModalProvider>
  );
};

export default RowMaterialContainer;
