import { configureStore } from "@reduxjs/toolkit";

import addProgrammingReducer from "../redux/slices/addProgrammingSlice";
import summaryReducer from "../redux/slices/summarySlice";

const rootReducer = {
  programming: addProgrammingReducer,
  orderSummary: summaryReducer,
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (defaultMW) => defaultMW(),
});

export default store;
