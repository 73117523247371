import React from 'react'
import Utils from '../../../utilites/Utils'

const Dashboard = () => {
  
  
  return (
    <div>Welcome! {Utils.getManufactureUser()?.name}  Manufacturer </div>
  )
}

export default Dashboard