import React, { useEffect, useState } from "react";
import { Button, Empty, Row, Spin } from "antd";
import ApiCalling from "../../../network/ApiCalling";
import Utils from "../../../utilites/Utils";
import { BiPlus } from "react-icons/bi";
import ItemCard from "./ItemCard";

const ItemStep = ({
  isModalOpen,
  orderId,
  handleEditFn,
  handleRawDetails,
  itemlenght,
}) => {
  // console.log("state id", orderId);

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getItemsList();
  }, [orderId, handleEditFn]);

  const getItemsList = () => {
    setLoading(true);
    ApiCalling.apiCallGet(`item/get-by-orderId/${orderId}`)
      .then((res) => {
        if (Utils.checkAPISuccessStatus(res)) {
          setItems(res?.data);
          itemlenght(res?.data?.length);
        }
      })
      .catch((err) => {
        console.log("ERROR", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Spin spinning={loading} className="py-2">
        {items.length > 0 ? (
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {items.map((item) => (
              <ItemCard
                item={item}
                key={item?._id}
                handleRawDetails={handleRawDetails}
                handleEditFn={handleEditFn}
              />
            ))}
          </Row>
        ) : (
          !loading && (
            <div className="flex items-center flex-col justify-center w-full py-6">
              <Empty description="You don't have items. Please create an item for the order." />
              <Button
                type="primary"
                className="rounded h-[40px] font-sans mt-[20px] "
                icon={<BiPlus size={20} className="mx-0 space-x-0 px-0 m-0" />}
                onClick={() => {
                  isModalOpen(true);
                }}
              >
                Add Item
              </Button>
            </div>
          )
        )}
      </Spin>
    </>
  );
};

export default ItemStep;
