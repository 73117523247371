import React, { useEffect, useState } from "react";
import ApiCalling from "../../../../network/ApiCalling";
import { Button, Empty, message, Popconfirm, Space, Table } from "antd";
import Main from "../../../../components/common/Main";
import { BiPlus } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Utils from "../../../../utilites/Utils";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Footer } from "antd/es/layout/layout";

const Challan = () => {
  const navigate = useNavigate();
  const [challanList, setChallanList] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    List();
  }, []);
  function List() {
    setLoading(true);
    ApiCalling.apiCallGet(`order-chalaan/manufacture`)
      .then((res) => {
        // console.log("order-chalaan", res.data);
        setChallanList(res?.data);
      })
      .catch((err) => err)
      .finally(() => {
        setLoading(false);
      });
  }

  const handleShowsummary = (subOrder) => {
    navigate("/manufacturer-dashboard/create-challan", {
      state: { subOrder, isSummary: true },
    });
  };

  const handleCancelChallan = (challan) => {
    ApiCalling.apiCallPut(`order-chalaan/cancel/${challan?._id}`)
      .then((res) => {
        // console.log("cancel", res);
        if (Utils.checkAPISuccessStatus(res)) {
          message.success(res?.data?.message);
          List();
        }
      })
      .catch((err) => {
        console.log("ERR", err);
      });
  };

  const columns = [
    {
      title: "Challan Date",
      render: (_, date) => Utils.formateDateForRead(date?.date),
    },
    {
      title: "Status",
      render: (_, status) => Utils.challanStatus(status?.status),
    },
    {
      render: (_, subOrder) => (
        <Space className="flex gap-4">
          <EyeOutlined
            role="button"
            className="text-stroke"
            onClick={() => handleShowsummary(subOrder)}
          />

          <Popconfirm
            icon={<DeleteOutlined className=" text-nero" />}
            title="Cancel the Challan"
            description="Are you sure to Cancel this Challan?"
            onConfirm={() => handleCancelChallan(subOrder)}
            okText="Yes"
            cancelText="No"
            placement="right"
          >
            <DeleteOutlined className="text-stroke" />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Main>
        <div className="flex items-start justify-between">
          <h1 className="text-extralarge font-bold pb-[30px]">Challan</h1>
          <div className="flex items-center gap-2">
            <Button
              className="rounded h-[40px] font-sans "
              onClick={() => navigate("/manufacturer-dashboard/cancel-challan")}
            >
              See Cancelled
            </Button>

            <Button
              type="primary"
              className="rounded h-[40px] font-sans "
              icon={<BiPlus size={20} className="" />}
              onClick={() => navigate("/manufacturer-dashboard/create-challan")}
            >
              Create Challan
            </Button>
          </div>
        </div>

        <Table
          loading={loading}
          rowHoverable={false}
          columns={columns}
          className="bg-white  rounded-[6px] "
          dataSource={challanList?.map((item) => ({
            ...item,
            key: item?._id,
          }))}
          pagination={false}
          locale={{
            emptyText: (
              <Empty
                description="You don't have any challan, Create one."
                // children={
                //   <Button
                //     type="primary"
                //     className="rounded h-[40px] font-sans "
                //     icon={<BiPlus size={20} className="" />}
                //     onClick={() =>
                //       navigate("/manufacturer-dashboard/create-challan")
                //     }
                //   >
                //     Create Challan
                //   </Button>
                // }
              />
            ),
          }}
        />
      </Main>
    </>
  );
};

export default Challan;
