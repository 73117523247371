import React, { useEffect, useRef, useState } from "react";
import Main from "../../components/common/Main";
import BreadcrumbComponent from "../../components/breadcrumb/BreadcrumbComponent ";
import Card from "../../components/common/Card";
import { Button, message, Steps, theme } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { BiPlus } from "react-icons/bi";
import ItemStep from "./item/ItemStep";
import VendorStep from "./vendor/VendorStep";
import RowMaterialContainer from "./rawMaterial/RowMaterialContainer";
import ItemAddEdit from "./item/ItemAddEdit";
import Utils from "../../utilites/Utils";
import Programming from "./programming/Programming";
import ProgramSummary from "../../components/summary/ProgramSummary";

const OrderStepper = () => {
  const vendorStepRef = useRef();
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const { state } = useLocation();
  const [createdOrder, setCreatedOrder] = useState({});
  const flag = state?.isItem || false;
  const [current, setCurrent] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [editItemData, setEditItemData] = useState(null);
  const [isRawModal, setIsRowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [itemLenght, setItemsLength] = useState(0);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const order = state?.order;
  // console.log("createOrder", createdOrder);

  // console.log("state", state);

  const breadcrumbItems = [
    { label: "Orders", path: "/orders" },
    state?.isPrograming
      ? { label: "Program" }
      : {
          label: state?.isItem
            ? "Order's Item"
            : state?._id
            ? "Edit Order"
            : "Create Order",
        },
  ];

  const handleEditItem = (item) => {
    setEditItemData(item);
    setOpenModal(true);
  };

  const handleRawDetails = (isOpen, item) => {
    setIsRowModal(isOpen);
    if (item) {
      setSelectedItem(item);
    }
  };

  const OpenModal = () => {
    if (state?.order?._id || createdOrder?._id) {
      setOpenModal(true);
      handleOrderIdUpdate(createdOrder);
    } else {
      message.warning("Please create an order first.");
    }
  };

  const handleOrderIdUpdate = (order) => {
    setCreatedOrder(order);
  };
  const getItemLength = (lenght) => {
    setItemsLength(lenght);
  };

  const nextStep = () => setCurrent((prevCurrent) => prevCurrent + 1);

  const steps = [
    {
      title: "Vendor",
      content: (
        <VendorStep
          {...state}
          ref={vendorStepRef}
          onSubmitSuccess={nextStep}
          onOrderCreate={handleOrderIdUpdate}
        />
      ),
    },

    {
      title: "Item",
      content: (
        <ItemStep
          isModalOpen={OpenModal}
          handleEditFn={handleEditItem}
          handleRawDetails={handleRawDetails}
          orderId={state?.order?._id ? state?.order?._id : createdOrder?._id}
          itemlenght={getItemLength}
        />
      ),
    },
    {
      title: "Programming",
      content: (
        <>
          <Programming order={order?._id ? order : createdOrder} />
        </>
      ),
    },
  ];

  useEffect(() => {
    if (flag && order?._id) {
      setCurrent(1);
    }
  }, [flag, order?._id]);

  useEffect(() => {
    if (state?.isPrograming && order?._id) {
      setCurrent(2);
    }
  }, [state?.isPrograming, order?._id]);

  const next = () => {
    if (current === 0) {
      vendorStepRef.current.submitForm();
    } else {
      setCurrent(current + 1);
    }
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    backgroundColor: token.colorWhite,
    borderRadius: token.borderRadiusLG,
    marginTop: 16,
  };

  function CloseModal() {
    setOpenModal(false);
  }

  return (
    <Main>
      <div className="flex items-start justify-between">
        <div className="flex flex-col gap-0">
          <h1 className="text-extralarge font-bold">
            {state?.isPrograming
              ? `Program for order ${Utils.repraseOrderId(order?.orderId)}`
              : state?.isItem
              ? `${
                  itemLenght > 1 ? "Items" : "Item"
                }  for order ${Utils.repraseOrderId(order?.orderId)}`
              : state?._id
              ? "Edit Order"
              : "Create Order"}
          </h1>
          <BreadcrumbComponent items={breadcrumbItems} />
        </div>

        {current === 1 && (
          <Button
            type="primary"
            className="rounded h-[40px] font-sans "
            icon={<BiPlus size={20} className="mx-0 space-x-0 px-0 m-0" />}
            onClick={() => {
              setEditItemData({});
              setOpenModal(true);
            }}
          >
            Add Item
          </Button>
        )}
      </div>
      <Card className="mt-[30px]  ">
        {!order?._id && (
          <Steps
            size="small"
            className="py-3 ml-0 bg-input   px-3 rounded w-full"
            current={current}
            items={items}
          />
        )}

        <div style={contentStyle}>{steps[current].content}</div>
        <div className="flex justify-end ">
          <div className="pl-[30px] flex items-center gap-2 ">
            {!state?.isPrograming && !state?.isItem && (
              <Button onClick={() => navigate(-1)}>Cancel</Button>
            )}
            <>
              {current < steps.length - 1 && (
                <>
                  {!order?._id && !state?.isProgramming && (
                    <Button
                      className="my-[10px] pl-[10px]"
                      type="primary"
                      onClick={() => {
                        if (state?._id) {
                          setTimeout(() => {
                            navigate("/orders");
                          }, 1000);
                        }
                        next();
                      }}
                    >
                      {state?._id ? "Save" : "Next"}
                    </Button>
                  )}
                </>
              )}
            </>

            {current === steps.length - 1 ||
              (state?.isPrograming && (
                <Button
                  type="primary"
                  className="mt-[20px]"
                  onClick={() => {
                    setTimeout(() => {
                      navigate("/orders");
                    }, 1500);
                    message.success("Order complete!");
                  }}
                >
                  Done
                </Button>
              ))}
          </div>
        </div>
      </Card>

      {openModal && (
        <ItemAddEdit
          isModalOpen={openModal}
          OnClose={CloseModal}
          editItemData={editItemData}
          orderId={order?._id || createdOrder?._id}
        />
      )}

      <RowMaterialContainer
        isModalOpen={isRawModal}
        OnClose={() => setIsRowModal(false)}
        item={selectedItem}
      />
      {state?.isPrograming && (
        <ProgramSummary order={order?._id ? order : createdOrder} />
      )}
    </Main>
  );
};

export default OrderStepper;
