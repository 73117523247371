import React from "react";
import Utils from "../../../../utilites/Utils";
import { CheckOutlined, DeleteOutlined } from "@ant-design/icons";
import { message, Popconfirm, Timeline } from "antd";
import { LuClock, LuClock4, LuListChecks } from "react-icons/lu";
import { FaCheck } from "react-icons/fa6";
import { BiAddToQueue } from "react-icons/bi";
import ApiCalling from "../../../../network/ApiCalling";

const TimeLine = ({
  updates,
  pendingQuantity,
  handleUpdateSubOrder,
  subOrder,

  handleExpand = () => {},
}) => {
  const handleDeleteTimeline = async (id) => {
    try {
      const res = await ApiCalling.apiCallDelete(
        `suborder-update/delete/${id}`
      );
      if (Utils.checkAPISuccessStatus(res)) {
        message.success(res?.data?.message);
        handleExpand(true, subOrder);
      }
    } catch (err) {
      console.error("Error deleting timeline:", err);
    }
  };
  return (
    <Timeline
      pendingDot={
        pendingQuantity ? (
          <LuClock4 size={18} color="red " />
        ) : (
          <FaCheck size={18} color="green" />
        )
      }
      pending={
        pendingQuantity > 0 && (
          <div className="flex items-center gap-5">
            <span>{` ${pendingQuantity} is still pending`}</span>
            <BiAddToQueue
              className="text-stroke"
              role="button"
              onClick={() => handleUpdateSubOrder(subOrder)}
              size={18}
            />
          </div>
        )
      }
    >
      {updates?.map((update) => (
        <Timeline.Item
          key={update?._id}
          dot={
            update.status === 0 ? (
              <LuClock
                size={18}
                style={{
                  color: Utils.updatesStatus[update?.status].color,
                }}
              />
            ) : update.status === 1 ? (
              <LuListChecks
                size={18}
                style={{
                  color: Utils.updatesStatus[update?.status].color,
                }}
              />
            ) : update.status === 2 ? (
              <CheckOutlined
                size={18}
                style={{
                  color: Utils.updatesStatus[update?.status].color,
                }}
              />
            ) : (
              ""
            )
          }
        >
          <div className="flex gap-[30px]">
            <p
              className="mb-0"
              style={{
                color: Utils.updatesStatus[update?.status].color,
              }}
            >
              <span>{update.quantity}</span>
              {` Qty manufactured on ${Utils.formateDateForRead(
                update.date
              )},   `}
              <span>{Utils.updatesStatus[update.status].status}</span>
              {/* <Tag color={Utils.updatesStatus[update?.status].color}>
              {Utils.updatesStatus[update.status].status}
            </Tag> */}
            </p>

            {update?.status === 0 && (
              <Popconfirm
                destroyTooltipOnHide
                icon={<DeleteOutlined />}
                title="Delete the Order Update"
                description="Are you sure to delete this Order Update?"
                onConfirm={() => handleDeleteTimeline(update?._id)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined className="text-stroke" />
              </Popconfirm>
            )}
          </div>
        </Timeline.Item>
      ))}
    </Timeline>
  );
};

export default TimeLine;
