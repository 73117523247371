const Const = {
  USER_TOKEN_KEY: "user_token",
  KEY_USER: "tjm_user",
  KEY_REGISTERED_USER: "registerd_user",
  MANUFACTURER_TOKEN:"MANUFACTURER_TOKEN",
  MANUFACTURER_USER:"MANUFACTURER_USER",
  MANUFACTURER_REGISTERED:"MANUFACTURER_REGISTERED",
  
};

export default Const;
export const ITEM_IMAGE_URL = process.env.REACT_APP_BASE_URL + "uploads/items/";
