import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../manufacturerLayout/sidebar/Sidebar";
import Topbar from "../manufacturerLayout/Topbar/Topbar";
 

const ManuFactureLayout = () => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  return (
    <div className="flex h-screen">
      <Sidebar collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
      <div className="flex-1 flex flex-col">
        <Topbar collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
        <main className="p-6 bg-secondary-light flex-1 overflow-auto">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default ManuFactureLayout;
