import React, { useEffect, useState } from "react";
import Main from "../../components/common/Main";
import { Button, Space, Spin, Table } from "antd";
import { BiPlus } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import ApiCalling from "../../network/ApiCalling";
import Utils from "../../utilites/Utils";

const Manufacturer = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [manufacturer, setManufacturer] = useState([]);

  useEffect(() => {
    getManufacturerList();
  }, []);
  const getManufacturerList = () => {
    setLoading(true);
    ApiCalling.apiCallGet("manufacturer/get")
      .then((res) => {
        setLoading(false);
        if (Utils.checkAPISuccessStatus) {
          // console.log("res", res.data);
          // setManufacturer(res?.data);
          setManufacturer(
            res.data.map((manufacturer) => ({
              ...manufacturer,
              key: manufacturer._id,
            }))
          );
        }
      })
      .catch((err) => console.error("ERROR", err));
  };

  const handleEdit = (manufaturerId) => {
    navigate(`/create-manufacturer`, {
      state: manufaturerId,
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Contact Person",
      dataIndex: "contactPerson",
      key: "contactPerson",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Machine",
      dataIndex: "machine",
      key: "machine",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <FiEdit
            role="button"
            className="text-neutral-400 cursor-pointer"
            onClick={() => handleEdit(record)}
          />
        </Space>
      ),
    },
  ];

  return (
    <Main>
      <div className="flex items-start justify-between">
        <h1 className="text-extralarge font-bold pb-[30px]">Manufacturer</h1>
        <Button
          type="primary"
          className="rounded h-[40px] font-sans "
          icon={<BiPlus size={20} />}
          onClick={() => navigate("/create-manufacturer")}
        >
          Create Manufacturer
        </Button>
      </div>

      <Table
        loading={loading}
        columns={columns}
        dataSource={manufacturer.map((item) => ({ ...item, key: item._id }))}
        className="bg-white   rounded-[6px] font-serif"
        pagination={true}
      />
    </Main>
  );
};

export default Manufacturer;
