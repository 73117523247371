import React, { useEffect, useState } from "react";
import { DatePicker, Input, message, Popconfirm, Select, Spin } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import Utils, { daysDifference } from "../../../utilites/Utils";
import ApiCalling from "../../../network/ApiCalling";
import { FaRegFloppyDisk } from "react-icons/fa6";
import { FiEdit, FiXCircle } from "react-icons/fi";
import Placeholder from "../../../assests/icons/imag-holder.png";
import { ITEM_IMAGE_URL } from "../../../utilites/Const";
import { DeleteOutlined } from "@ant-design/icons";
import AddProgramming from "./AddProgramming";
import {
  setProgrammingList,
  setManufacturerList,
} from "../../../redux/slices/addProgrammingSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrderItemsList } from "../../../redux/thunk/actions";

const Programming = ({ order }) => {
  const dispatch = useDispatch();
  const [editingRow, setEditingRow] = useState(null);
  const [editData, setEditData] = useState(null);
  const [loading, setLoading] = useState(false);

  const { programmingList, manufacturerList, itemList } = useSelector(
    (state) => state?.programming
  );

  const mOption = manufacturerList?.map((m) => ({
    label: m?.name,
    value: m?._id,
    machine: m?.machine,
  }));

  useEffect(() => {
    getProgrammingList();
  }, [order?._id, editingRow]);

  const getProgrammingList = () => {
    ApiCalling.apiCallGet(`order-programming/get/${order?._id}`)
      .then((res) => {
        dispatch(setProgrammingList(res?.data));
        dispatch(fetchOrderItemsList(order?._id));
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    dispatch(fetchOrderItemsList(order._id));
  }, []);

  useEffect(() => {
    getManufacturerList();
  }, []);

  const getManufacturerList = () => {
    ApiCalling.apiCallGet("manufacturer/get")
      .then((res) => {
        if (Utils.checkAPISuccessStatus) {
          dispatch(setManufacturerList(res?.data));
        }
      })
      .catch((err) => console.error("ERROR", err));
  };

  const handleEditClick = (prog, id) => {
    setEditingRow(prog._id);
    setEditData({
      ...prog,
      startDate: dayjs(prog?.startDate),
      endDate: dayjs(prog?.endDate),
      manufacture: prog?.manufacture?._id,
    });
  };

  const handleFieldChange = (field, value) => {
    setEditData({
      ...editData,
      [field]: value,
    });
  };

  const handleSave = (id) => {
    try {
      const bodyData = {
        _id: id,
        item: editData?.item?._id,
        manufacture: editData?.manufacture,
        startDate: editData?.startDate,
        endDate: editData?.endDate,
        machine: editData?.machine,
        estimatedQuantity: calculateEstimate(editData),
      };

      ApiCalling.apiCallPut(`order-programming/update`, bodyData)
        .then((res) => {
          if (Utils.checkAPISuccessStatus(res)) {
            message.success(res?.data?.message);
            setEditingRow(null);
            setEditData(null);
            dispatch(fetchOrderItemsList(order?._id));
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    } catch (error) {
      console.error("Error in handleSave:", error);
    }
  };

  const handleCancel = () => {
    setEditingRow(null);
  };
  const handleDeleteProgrammingItem = (id) => {
    ApiCalling.apiCallDelete(`order-programming/delete/${id}`).then((res) => {
      if (Utils.checkAPISuccessStatus(res)) {
        message.success(res?.data?.message);
        getProgrammingList();
        dispatch(fetchOrderItemsList(order._id));
      }
    });
  };

  const handleRefresh = (shouldRefresh) => {
    if (shouldRefresh) {
      getProgrammingList();
    }
  };

  const calculateEstimate = (prog) => {
    const remainingQuantity =
      itemList.find((item) => item?._id === editData?.item?._id)
        ?.remainingQuantity || 0;

    if (!remainingQuantity) {
      const estimate =
        editData?.endDate && editData?.startDate
          ? Utils.daysDifference(editData?.endDate, editData?.startDate) *
            editData?.item?.MachineProductionPerDay *
            editData?.machine
          : 0;
      return Math.min(estimate, prog.estimatedQuantity);
    }
    const estimate =
      editData?.endDate && editData?.startDate
        ? (dayjs(editData?.endDate).diff(dayjs(editData?.startDate), "days") +
            1) *
          editData?.item?.MachineProductionPerDay *
          editData?.machine
        : 0;

    if (estimate > remainingQuantity + prog.estimatedQuantity) {
      return remainingQuantity + prog.estimatedQuantity;
    }
    return estimate;
  };
  return (
    <>
      <table className="table-auto w-full border-collapse ">
        <thead>
          <tr>
            <th className="tr-style">Order Items</th>
            <th className="tr-style">Manufacturer</th>
            <th className="tr-style">Machines</th>
            <th className="tr-style">Production start date</th>
            <th className="tr-style">End date</th>
            <th className="tr-style">Days</th>
            <th className="tr-style">Estimated Qty</th>
            <th className="tr-style"></th>
          </tr>
        </thead>

        <tbody className="text-[14px] w-full">
          <>
            {loading ? (
              <tr>
                <td colSpan={"100%"}>
                  <Spin
                    className=" w-full flex justify-center h-[200px] items-center"
                    size="small"
                  />
                </td>
              </tr>
            ) : (
              programmingList?.map((prog) => (
                <tr key={prog?._id} className="">
                  <td className=" py-3 flex gap-[10px] items-center  border-b-[1px] border-stroke-light">
                    <div>
                      <img
                        src={
                          prog?.item?.image
                            ? ITEM_IMAGE_URL + prog?.item?.image
                            : Placeholder
                        }
                        className="object-cover w-[40px] rounded"
                        alt=""
                      />
                    </div>

                    <div>
                      {editingRow === prog._id ? (
                        <>
                          <Select
                            disabled={editingRow === prog?._id}
                            className="w-full h-[40px] small-chevron"
                            value={editData?.item?.title || null}
                            options={itemList}
                            onChange={(value) =>
                              handleFieldChange("item", value)
                            }
                          />
                        </>
                      ) : (
                        <span className="text-nowrap">{prog?.item?.title}</span>
                      )}
                      <div className="text-[6px] text-gray-400">
                        {Utils.repraseManuOrderId(prog?.subOrderId)}
                      </div>
                    </div>
                  </td>
                  <td className="px-4 py-3  border-b-[1px] border-stroke-light">
                    {editingRow === prog?._id ? (
                      <Select
                        value={editData?.manufacture}
                        className="w-full h-[40px] small-chevron"
                        options={mOption}
                        name="manufacture"
                        onChange={(value) => {
                          handleFieldChange("manufacture", value);
                          console.log("set", value);
                        }}
                      />
                    ) : (
                      prog.manufacture?.name
                    )}
                  </td>
                  <td className="px-4  border-b-[1px] border-stroke-light h-[40px]">
                    {editingRow === prog._id ? (
                      <Input
                        value={editData?.machine}
                        className="h-[40px] w-[75px] "
                        name="machine"
                        onChange={(e) =>
                          handleFieldChange("machine", e.target.value)
                        }
                      />
                    ) : (
                      prog?.machine
                    )}
                  </td>
                  <td className="px-4 py-3 border-b-[1px] border-stroke-light text-nowrap">
                    {editingRow === prog._id ? (
                      <>
                        <DatePicker
                          disabledDate={(current) =>
                            current.isBefore(
                              moment(order?.orderDate).startOf("day")
                            ) ||
                            current.isAfter(
                              moment(order?.deliveryDate).endOf("day")
                            )
                          }
                          className="max-w-[125px] h-[40px]"
                          value={
                            editData?.startDate
                              ? dayjs(editData.startDate, "YYYY-MM-DD")
                              : null
                          }
                          name="startDate"
                          onChange={(date, dateString) => {
                            handleFieldChange(
                              "startDate",
                              date ? date.format("YYYY-MM-DD") : null
                            );
                          }}
                        />
                      </>
                    ) : (
                      <>{Utils.formateDateForRead(prog?.startDate)}</>
                    )}
                  </td>

                  <td className="px-4 py-3  border-b-[1px] border-stroke-light text-nowrap">
                    {editingRow === prog._id ? (
                      <DatePicker
                        disabledDate={(current) =>
                          current.isBefore(moment(order?.orderDate)) ||
                          current.isAfter(moment(order?.deliveryDate))
                        }
                        className="max-w-[125px] h-[40px]"
                        value={
                          editData?.endDate
                            ? dayjs(editData?.endDate, "YYYY-MM-DD")
                            : null
                        }
                        name="endDate"
                        onChange={(date, dateString) =>
                          handleFieldChange(
                            "endDate",
                            date ? date.format("YYYY-MM-DD") : null
                          )
                        }
                      />
                    ) : (
                      <>{Utils.formateDateForRead(prog?.endDate)}</>
                    )}
                  </td>
                  <td className="px-4 py-3  border-b-[1px] border-stroke-light text-nowrap">
                    <>
                      {editingRow === prog._id ? (
                        <span>
                          {editData?.endDate && editData?.startDate
                            ? dayjs(editData?.endDate).diff(
                                dayjs(editData?.startDate),
                                "days"
                              ) + 1
                            : 0}
                        </span>
                      ) : (
                        <>
                          {prog?.startDate && prog?.endDate
                            ? dayjs(prog?.endDate).diff(
                                dayjs(prog?.startDate),
                                "days"
                              ) + 1
                            : 0}
                        </>
                      )}
                    </>
                  </td>
                  <td className="px-4 py-3  border-b-[1px] border-stroke-light text-nowrap">
                    {editingRow === prog._id ? (
                      <>
                        <span>{calculateEstimate(prog)}</span>
                      </>
                    ) : (
                      <>{prog?.estimatedQuantity}</>
                    )}
                  </td>

                  <td className="py-3 border-b-[1px] border-stroke-light">
                    {editingRow === prog._id ? (
                      <div className="flex items-center space-x-4 px-[12px]">
                        <FaRegFloppyDisk
                          role="button"
                          onClick={() => handleSave(prog?._id)}
                          title="Save"
                          className="cursor-pointer"
                        />
                        <FiXCircle
                          role="button"
                          onClick={() => handleCancel()}
                          title="Cancel"
                          className="cursor-pointer"
                        />
                      </div>
                    ) : (
                      <div className="flex items-center space-x-4 px-[12px]">
                        <FiEdit
                          role="button"
                          onClick={() => handleEditClick(prog, order?._id)}
                          title="Edit"
                          className="cursor-pointer"
                        />

                        <Popconfirm
                          placement="topLeft"
                          title="Delete the programming"
                          icon={<DeleteOutlined />}
                          description="Are you sure to delete this programming?"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() =>
                            handleDeleteProgrammingItem(prog?._id)
                          }
                        >
                          <DeleteOutlined />
                        </Popconfirm>
                      </div>
                    )}
                  </td>
                </tr>
              ))
            )}
          </>
        </tbody>
      </table>
      <AddProgramming order={order} onRefresh={handleRefresh} />
    </>
  );
};

export default Programming;
