import React from "react";
import Utils from "../../../utilites/Utils";
import { CalendarOutlined } from "@ant-design/icons";

const DispatchUpdates = ({ data }) => {
  return (
    <div className="overflow-x-auto overflow-hidden mt-[30px]">
      <div className="p-3  bg-green-100 border-1 rounded     ">
        <p className="text-gray-800  text-sm font-medium mb-2 rounded   px-2  flex items-center gap-[5px]">
          {`Order Updates on ${Utils.formateDateForRead(data?.updateDate)} `}
        </p>
        <table className="table-auto w-full border-collapse rounded overflow-hidden bg-white">
          <thead className="border-stroke">
            <tr>
              <th className=" border-gray-200 border-b border-stroke-light px-4 py-1 text-stroke  text-left font-semibold">
                Title
              </th>
              <th className=" border-gray-200 border-b border-stroke-light px-4 py-1 text-stroke  text-left font-semibold">
                Quantity
              </th>
            </tr>
          </thead>

          <tbody>
            {data?.material?.map((m, index) => (
              <tr
                className="text-sm text-nero hover:bg-gray-50 transition-all"
                key={index}
              >
                <td className="border-b  text-nero border-stroke-light px-4 py-1 ">
                  {m?.title}
                </td>
                <td className="border-b  text-nero border-stroke-light px-4 py-1 ">
                  {m?.quantity} {m?.unit}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DispatchUpdates;
