import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Col, DatePicker, Form, message, Row, Select } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";
import ApiCalling from "../../../network/ApiCalling";
import Utils from "../../../utilites/Utils";
import moment from "moment";
import dayjs from "dayjs";
const VendorStep = forwardRef((props, ref) => {
  const formikRef = useRef();
  const [loading, setLoading] = useState(false);
  const [vendor, setVendor] = useState([]);

  useEffect(() => {
    getVendorList();
  }, []);

  const getVendorList = () => {
    setLoading(true);
    ApiCalling.apiCallGet("vendor/get")
      .then((res) => {
        setLoading(false);
        if (Utils.checkAPISuccessStatus(res)) {
          setVendor(
            res?.data?.map((vendor) => ({
              label: vendor.name,
              value: vendor._id,
            }))
          );
        }
      })
      .catch((err) => console.error("ERROR", err));
  };

  const handleFormSubmit = async (values, resetForm) => {
    setLoading(true);
    try {
      const apiCall = props?._id
        ? ApiCalling.apiCallPut("order/update", {
            ...values,
            id: props?._id,
          })
        : ApiCalling.apiCallPost("order/create", values);
      const res = await apiCall;
      if (Utils.checkAPISuccessStatus(res)) {
        props.onOrderCreate(res?.data?.order);
        message.success(res.data.message);

        setTimeout(() => {
          props.onSubmitSuccess();
        }, 1500);
        resetForm();
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    submitForm: () => formikRef.current.submitForm(),
  }));

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={{
        vendorId: props.vendor?._id || "",
        orderDate: props.orderDate,
        deliveryDate: props.deliveryDate,
      }}
      validationSchema={Yup.object().shape({
        vendorId: Yup.string().required("Vendor is required"),
        orderDate: Yup.string().required("Order Date is required"),
        deliveryDate: Yup.string().required("Delivery Date is required"),
      })}
      onSubmit={(values, { resetForm }) => handleFormSubmit(values, resetForm)}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Row gutter={40} className="pl-[30px] pr-[30px] mt-4 mb-[20px]">
            <Col span={12}>
              <Form.Item
                layout="vertical"
                className="text-nero text-2xl"
                label="Vendor"
              >
                <Select
                  className="text-left w-full h-[45px] small-chevron "
                  placeholder="Select Vendor"
                  options={vendor}
                  value={values.vendorId || null}
                  onBlur={handleBlur}
                  onChange={(value) => setFieldValue("vendorId", value)}
                />

                {touched.vendorId && errors.vendorId && (
                  <span className="text-red-500 text-[13px] ">
                    {errors.vendorId}
                  </span>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={40} className="pl-[30px] pr-[30px]">
            <Col span={12}>
              <Form.Item
                layout="vertical"
                className="text-nero text-2xl"
                label="Order Date"
              >
                <DatePicker
                  name="orderDate"
                  className="w-full"
                  placeholder="Select Order Date"
                  format="YYYY-MM-DD"
                  value={
                    values.orderDate
                      ? dayjs(values.orderDate).startOf("day")
                      : null
                  }
                  onChange={(date, dateString) =>
                    setFieldValue(
                      "orderDate",
                      date ? moment(dateString, "YYYY-MM-DD") : null
                    )
                  }
                  onBlur={handleBlur}
                />
                {touched.orderDate && errors.orderDate && (
                  <span className="text-red-500 text-[13px]">
                    {errors.orderDate}
                  </span>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                layout="vertical"
                className="text-nero text-2xl"
                label="Delivery Date"
              >
                <DatePicker
                  name="deliveryDate"
                  placeholder="Select Delivery Date"
                  // disabledDate={(current) => current < moment().endOf("day")}
                  style={{
                    width: "100%",
                    height: 45,
                  }}
                  format="YYYY-MM-DD"
                  value={
                    values.deliveryDate
                      ? dayjs(values.deliveryDate).endOf("day")
                      : null
                  }
                  onChange={(date, dateString) =>
                    setFieldValue(
                      "deliveryDate",
                      date ? moment(dateString, "YYYY-MM-DD") : null
                    )
                  }
                  onBlur={handleBlur}
                />
                {touched.deliveryDate && errors.deliveryDate && (
                  <span className="text-red-500  text-[13px]  text-left">
                    {errors.deliveryDate}
                  </span>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row
            className="pl-[30px]  justify-end"
            style={{ paddingLeft: "30px", lineHeight: "1.2" }}
          ></Row>
        </form>
      )}
    </Formik>
  );
});

export default VendorStep;
