import React, { useEffect, useState } from "react";
import ModalProvider from "../../../components/common/ModalProvider";
import { Button, Col, Form, Input, message, Row, Steps, theme } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";
import Dragger from "antd/es/upload/Dragger";
import Utils from "../../../utilites/Utils";
import ApiCalling from "../../../network/ApiCalling";
import { CloudUploadOutlined } from "@ant-design/icons";
import { ITEM_IMAGE_URL } from "../../../utilites/Const";

const ItemAddEdit = ({ isModalOpen, OnClose, editItemData, orderId }) => {
  const { token } = theme.useToken();
  console.log(
    "editItemData and Order id for item create",
    editItemData,
    orderId
  );
  const [previewUrl, setPreviewUrl] = useState("");

  const CloseModal = () => {
    OnClose();
  };
  const handleItemSubmit = (values, resetForm) => {
    const formData = new FormData();

    Object.keys(values).forEach((key) => {
      formData.append(key, values[key]);
    });

    if (editItemData?._id) {
      ApiCalling.apiCallPutMultipart("item/update", {
        ...values,

        id: editItemData?._id,
      })
        .then((res) => {
          if (Utils.checkAPISuccessStatus(res)) {
            message.success(res?.data?.message);
            resetForm();
            CloseModal();
          }
        })
        .catch((err) => {
          console.log("ERR", err);
        });
    } else {
      ApiCalling.apiCallPostMultipart("item/create", formData)
        .then((res) => {
          if (Utils.checkAPISuccessStatus(res)) {
            message.success(res?.data?.message);
            resetForm();
            CloseModal();
            // setTimeout(() => {
            //   navigate("/orders");
            // }, 1500);
          }
        })
        .catch((err) => {
          console.error("Error submitting item:", err);
        });
    }
  };

  // console.log("OrderId", orderId);

  return (
    <ModalProvider isModalOpen={isModalOpen} OnClose={OnClose}>
      <Formik
        enableReinitialize
        initialValues={
          editItemData?._id
            ? editItemData
            : {
                orderId: orderId,
                title: "",
                quantity: "",
                MachineProductionPerDay: "",
                image: "",
              }
        }
        validationSchema={Yup.object().shape({
          title: Yup.string().required("Title is required"),
          quantity: Yup.string().required("Quantity is required"),
          MachineProductionPerDay: Yup.string().required(
            "Per machine /Per day production is required"
          ),
          // image: Yup.mixed().required("image is required"),
        })}
        onSubmit={(values, { resetForm }) =>
          handleItemSubmit(values, resetForm)
        }
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit} className="mt-[20px]">
              <Row gutter={40} className="pl-[30px] pr-[30px] ">
                <Col span={12}>
                  <Form.Item
                    layout="vertical"
                    className="text-nero text-2xl"
                    label="Title"
                  >
                    <Input
                      name="title"
                      value={values?.title}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.title && errors.title && (
                      <span className="text-red-500 text-[13px] ">
                        {errors.title}
                      </span>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    layout="vertical"
                    className="text-nero text-2xl"
                    label="Quantity"
                  >
                    <Input
                      name="quantity"
                      value={values.quantity}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.quantity && errors.quantity && (
                      <span className="text-red-500 text-[13px]  ">
                        {errors.quantity}
                      </span>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    layout="vertical"
                    className="text-nero text-2xl "
                    label="Estimate per machine  per day production"
                  >
                    <Input
                      name="MachineProductionPerDay"
                      value={values.MachineProductionPerDay || ""}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.MachineProductionPerDay &&
                      errors.MachineProductionPerDay && (
                        <span className="text-red-500 text-[13px] ">
                          {errors.MachineProductionPerDay}
                        </span>
                      )}
                  </Form.Item>
                </Col>
              </Row>
              <Row className="pl-[30px] pr-[30px] justify-end">
                <Col className="w-full">
                  <Form.Item
                    layout="vertical"
                    className="text-nero text-2xl mb-0"
                    label="Picture Upload"
                  >
                    <Dragger
                      beforeUpload={(file) => {
                        setFieldValue("image", file);
                        return false;
                      }}
                      className="w-full"
                      fileList={
                        values?.image && typeof values.image === "string"
                          ? [
                              {
                                uid: "-1",
                                name: "Uploaded Image",
                                status: "done",

                                // url: ITEM_IMAGE_URL + values.image,
                              },
                            ]
                          : undefined
                      }
                      onRemove={() => setFieldValue("image", null)}
                    >
                      {!values?.image || typeof values.image !== "string" ? (
                        <div className="h-[150px] justify-center items-center flex flex-col">
                          <p className="ant-upload-drag-icon">
                            <CloudUploadOutlined
                              className=""
                              color={token.blue}
                            />
                          </p>
                          <p className="ant-upload-text">
                            Choose Image or drag & drop it here
                          </p>
                        </div>
                      ) : (
                        <img
                          src={
                            typeof values.image === "string"
                              ? ITEM_IMAGE_URL + values.image // Show existing image
                              : URL.createObjectURL(values.image) // Show uploaded file preview
                          }
                          alt="Uploaded"
                          style={{
                            width: "100%",
                            maxHeight: "250px",
                            objectFit: "contain",
                          }}
                        />
                      )}
                    </Dragger>
                  </Form.Item>
                </Col>
                <Button htmlType="submit" type="primary" className="mt-[20px]">
                  Save
                </Button>
              </Row>
            </form>
          );
        }}
      </Formik>
    </ModalProvider>
  );
};

export default ItemAddEdit;
