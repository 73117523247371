import secureLocalStorage from "react-secure-storage";
import Const from "./Const";
import moment from "moment";
import { message, Tag } from "antd";
import dayjs from "dayjs";
// user save
const saveToken = (data) => {
  try {
    secureLocalStorage.setItem(Const.USER_TOKEN_KEY, JSON.stringify(data));
  } catch (error) {}
};

const getToken = () => {
  try {
    const user = secureLocalStorage.getItem(Const.USER_TOKEN_KEY);
    if (user !== undefined) {
      return JSON.parse(user);
    }
  } catch (error) {}
};

const removeToken = () => {
  try {
    secureLocalStorage.removeItem(Const.USER_TOKEN_KEY);
  } catch (error) {}
};

const getRegisteredUser = () => {
  try {
    const user = secureLocalStorage.getItem(Const.KEY_REGISTERED_USER);
    if (user !== undefined) {
      return JSON.parse(user);
    }
  } catch (error) {}
};

const saveRegisteredUser = (data) => {
  try {
    secureLocalStorage.setItem(Const.KEY_REGISTERED_USER, JSON.stringify(data));
  } catch (error) {}
};
const removeRegisteredUser = () => {
  try {
    secureLocalStorage.removeItem(Const.KEY_REGISTERED_USER);
  } catch (error) {}
};

// save manufacturer utilies

const saveManufactureToken = (data) => {
  try {
    secureLocalStorage.setItem(Const.MANUFACTURER_TOKEN, JSON.stringify(data));
  } catch (error) {}
};

const getManufactureToken = () => {
  try {
    const user = secureLocalStorage.getItem(Const.MANUFACTURER_TOKEN);
    if (user !== undefined) {
      return JSON.parse(user);
    }
  } catch (error) {}
};

const removeManufactureToken = () => {
  try {
    secureLocalStorage.removeItem(Const.MANUFACTURER_TOKEN);
  } catch (error) {}
};

const saveManufactureUser = (data) => {
  try {
    secureLocalStorage.setItem(Const.MANUFACTURER_USER, JSON.stringify(data));
  } catch (error) {}
};
const removeManufactureUser = (data) => {
  try {
    secureLocalStorage.removeItem(Const.MANUFACTURER_USER);
  } catch (error) {}
};

const getManufactureUser = () => {
  try {
    const user = secureLocalStorage.getItem(Const.MANUFACTURER_USER);
    if (user !== undefined) {
      return JSON.parse(user);
    }
  } catch (error) {}
};

// --------------------------------------------------

const checkAPISuccessStatus = (res) => {
  return res.status === 200 || res.status === 201;
};
const isValidData = (data) => {
  if (data === undefined) {
    return false;
  }
  if (data === null) {
    return false;
  }
  if (data === "") {
    return false;
  }
  return true;
};

function getMomentDateTimeFormat(format) {
  if (format === "MMMM d, yyyy") {
    return "MMMM D, YYYY";
  } else if (format === "yyyy, MMMM, dd") {
    return "YYYY, MMMM, DD";
  } else if (format === "yyyy-MM-dd") {
    return "YYYY-MM-DD";
  } else if (format === "MM/dd/yyyy") {
    return "MM/DD/YYYY";
  } else if (format === "dd/MM/yyyy") {
    return "DD/MM/YYYY";
  } else if (format === "HH:mm") {
    return "HH:mm";
  } else if (format === "hh:mm a") {
    return "hh:mm a";
  }
}

const formatDate = (dateString, format = "YYYY-MM-DD") => {
  if (!dateString) return "";
  return moment(dateString).format(format);
};

const formateDateForRead = (date) => moment(date).format("DD MMM YYYY");
function repraseOrderId(orderId) {
  if (orderId) return "#00000" + orderId;
  return orderId;
}

function repraseManuOrderId(subOrderId) {
  if (subOrderId) return "#" + subOrderId;
  return subOrderId;
}

function orderDateFilterStartDate(orderDate, deliveryDate, startDate) {
  // if (!orderDate || !deliveryDate || !startDate) {
  //   return false;
  // }
  const orderDateStart = moment(orderDate).startOf("day");
  const deliveryDateEnd = moment(deliveryDate).endOf("day");
  const startDateStart = moment(startDate).startOf("day");
  // console.log("====================================");
  // console.log(orderDateStart, deliveryDateEnd, startDateStart);
  // console.log("====================================");

  const isValid =
    startDateStart.isSameOrAfter(orderDateStart) &&
    startDateStart.isSameOrBefore(deliveryDateEnd);

  return isValid;
}

function orderDateFilterEndDate(orderDate, deliveryDate, endDate) {
  const orderDateStart = moment(orderDate).startOf("day");
  const deliveryDateEnd = moment(deliveryDate).endOf("day");
  const endDateEnd = moment(endDate).endOf("day");
  // const startDateStart = moment(startDate).startOf("day");

  const check =
    endDateEnd.isSameOrAfter(orderDateStart) &&
    endDateEnd.isSameOrBefore(deliveryDateEnd);
  return check;
}

function checkStatus(status) {
  let color;
  let message = "";
  switch (status) {
    case 0:
      color = "red";
      message = "Pending";
      break;
    case 1:
      color = "orange";
      message = "In Progress";
      break;
    case 2:
      color = "green";
      message = "Complete";

      break;
    default:
      color = "blue";
  }
  return <Tag color={color}>{message}</Tag>;
}
function checkMOrderStatus(status) {
  let color;
  let message = "";
  switch (status) {
    case 0:
      color = "red";
      message = "Pending";
      break;

    case 1:
      color = "green";
      message = "Completed";
      break;

    default:
      color = "blue";
      message = "No";
  }
  return <Tag color={color}>{message}</Tag>;
}
// challan

function challanStatus(status) {
  let color;
  let message = "";
  switch (status) {
    case 0:
      color = "orange";
      message = "Challan Pending";
      break;
    case 1:
      color = "green";
      message = "Challan Approved";
      break;
    case 2:
      color = "red";
      message = "Challan Cancelled";

      break;
    default:
      color = "blue";
  }
  return <Tag color={color}>{message}</Tag>;
}

// Reusable status check
const statusConfig = {
  order: {
    0: { color: "red", message: "Pending" },
    1: { color: "orange", message: "In Progress" },
    2: { color: "green", message: "Complete" },
  },
  mOrder: {
    0: { color: "red", message: "Pending" },
    // 1: { color: "green", message: "aprroved" },
    1: { color: "green", message: "Aprroved" },
  },
  updatesOrder: {
    0: { color: "black", status: "pending" },
    1: { color: "orange", status: "generated" },
    2: { color: "green", status: "approved" },
  },
};

function getStatus(type, status, options = { onlyMessage: false }) {
  const statusDetails = statusConfig[type]?.[status] || {
    color: "blue",
    message: "Unknown",
  };
  const displayMessage =
    statusDetails.message || statusDetails.status || "Unknown";

  if (options.onlyMessage) {
    return displayMessage;
  }

  if (options.returnPlainObject) {
    return statusDetails;
  }
}

const updatesStatus = {
  0: { status: "but challan is pending", color: "black" },
  1: { status: "challan is generated", color: "orange" },
  2: { status: "challan is approved", color: "green" },
};

export function daysDifference(endDate, startDate) {
  return dayjs(endDate).diff(dayjs(startDate), "days") + 1;
}

export const calculateEstimate = (prog, editData, itemList) => {
  const remainingQuantity =
    itemList?.find((item) => item?._id === editData?.item?._id)
      ?.remainingQuantity || 0;

  if (!remainingQuantity) {
    const estimate =
      editData?.endDate && editData?.startDate
        ? daysDifference(editData?.endDate, editData?.startDate) *
          editData?.item?.MachineProductionPerDay *
          editData?.machine
        : 0;
    return Math.min(estimate, prog?.estimatedQuantity);
  }
  const estimate =
    editData?.endDate && editData?.startDate
      ? (dayjs(editData?.endDate).diff(dayjs(editData?.startDate), "days") +
          1) *
        editData?.item?.MachineProductionPerDay *
        editData?.machine
      : 0;

  if (estimate > remainingQuantity + prog.estimatedQuantity) {
    return remainingQuantity + prog.estimatedQuantity;
  }
  return estimate;
};

export default {
  updatesStatus,
  getStatus,
  repraseOrderId,
  checkMOrderStatus,
  repraseManuOrderId,
  checkAPISuccessStatus,
  saveToken,
  getToken,
  removeToken,
  getRegisteredUser,
  removeRegisteredUser,
  saveRegisteredUser,
  isValidData,
  getMomentDateTimeFormat,
  formatDate,
  orderDateFilterStartDate,
  orderDateFilterEndDate,
  checkStatus,
  getManufactureToken,
  removeManufactureToken,
  removeManufactureUser,
  saveManufactureToken,
  getManufactureUser,
  saveManufactureUser,
  formateDateForRead,
  challanStatus,
  daysDifference,
  calculateEstimate,
};
