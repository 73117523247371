import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Utils from "../utilites/Utils";

const ProtectedRoute = ({ children, role }) => {
  const [token, setToken] = useState(Utils.getToken());
  const [manufactureToken, setManufacturerToken] = useState(
    Utils.getManufactureToken
  );

  useEffect(() => {
    setToken(Utils.getToken());
    setManufacturerToken(Utils.getManufactureToken());
  }, []);

  if (role === "admin" && !token) {
    return <Navigate to="/login" />;
  }
   
  if (role === "manufacturer" && !manufactureToken) {
    return <Navigate to="/manufacturer-login" />;
  }

  return children;
};

export default ProtectedRoute;
