import { DatePicker } from "antd";
import { useFormikContext } from "formik";
import moment from "moment";
import React from "react";
import dayjs from "dayjs";

const ChallanDate = ({ onDateChange }) => {
  const { values, setFieldValue, handleBlur, touched, errors } =
    useFormikContext();
  return (
    <div>
      <DatePicker
        name="date"
        placeholder="Select Date"
        className="h-[45px] w-full"
        value={values.date ? dayjs(values.date) : null}
        onChange={(date, dateString) => {
          const selectedDate = date ? moment(dateString, "YYYY-MM-DD") : null;
          setFieldValue("date", selectedDate);
          onDateChange(selectedDate);
        }}
        onBlur={handleBlur}
      />
      {touched.date && errors.date && (
        <span className="text-red-500 text-[13px] text-left">
          {errors.date}
        </span>
      )}
    </div>
  );
};

export default ChallanDate;
