import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ApiCalling from "../../../network/ApiCalling";
import Main from "../../../components/common/Main";
import Card from "../../../components/common/Card";
import { Button, Calendar, Empty, Spin } from "antd";
import moment from "moment";
import BreadcrumbComponent from "../../../components/breadcrumb/BreadcrumbComponent ";
import { BiChevronLeft, BiChevronRight, BiPlus } from "react-icons/bi";
import Dispatch from "./dispatch";
import ModalProvider from "../../../components/common/ModalProvider";
import Utils from "../../../utilites/Utils";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrderSummary } from "../../../redux/thunk/actions";
import DispatchUpdates from "./dispatchUpdates";
import { CalendarOutlined, PlusOutlined } from "@ant-design/icons";

const OrderPlan = () => {
  const location = useLocation();
  const { _id } = location.state?.order;
  const state = location.state;
  const order = location.state?.order;
  const [spin, setSpin] = useState(false);

  // console.log(state);

  // const [summary, setSummary] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [manufacturer, setManufacturer] = useState({});

  const [seeModal, setSeeModal] = useState(false);
  const [dispatchedItem, setDispatchedItem] = useState([]);
  const { summary, loading } = useSelector((state) => state?.orderSummary);
  // console.log("summ", summary);

  const dispatch = useDispatch();
  const breadcrumbItems = [
    { label: "Orders", path: "/orders" },
    state?.isPlan && { label: "Order Management" },
  ];

  useEffect(() => {
    dispatch(fetchOrderSummary(_id));
  }, [dispatch, _id]);

  const windowHeight = window?.innerHeight;

  function getMaterialData(manufacturer) {
    const material = {};
    // console.log("foree", manufacturer);
    manufacturer.items.forEach((i) =>
      i.itemData.material.forEach((m) => {
        if (material[m.rawMaterial._id]) {
          material[m.rawMaterial._id].total += m.totalPieces;
        } else {
          material[m.rawMaterial._id] = {
            total: m.totalPieces,
            data: m.rawMaterial,
          };
        }
      })
    );
    const keys = Object.keys(material);
    for (const k of keys) {
      if (!manufacturer?.dispatchItems?.length) {
        material[k].totalDispatched = "-";
        material[k].usedQuantity = "-";
        material[k].remainingQuantity = "-";
        continue;
      }
      const dispatch = manufacturer?.dispatchItems?.find((val) => val._id == k);
      const usedQuantity = manufacturer?.materialUsed?.find(
        (val) => val?._id == k
      );
      if (dispatch) material[k].totalDispatched = dispatch.totalDispatched;
      else material[k].totalDispatched = "-";
      if (usedQuantity) material[k].usedQuantity = usedQuantity.totalQuantity;
      else material[k].usedQuantity = "-";
      if (material[k].totalDispatched === "-") {
        material[k].remainingQuantity = "-";
      }
      const usedQty =
        material[k]?.usedQuantity === "-" ? 0 : material[k]?.usedQuantity;
      const remainingQuantity = material[k].totalDispatched - usedQty;
      material[k].remainingQuantity = remainingQuantity;
    }
    return material;
  }

  const flattenedData = summary?.map((manufacturer) => {
    return {
      manufacturer: manufacturer.manufacturer,
      dates: manufacturer.items.map((item) => ({
        startDate: item.startDate,
        endDate: item.endDate,
      })),
      rm: getMaterialData(manufacturer),
    };
  });
  // console.log("flattenedData", flattenedData);

  const handleDispatch = (m) => {
    if (!m?.manufacturer?.id) return;
    setManufacturer(m);
    setOpenModal(true);
  };
  const handleallSeeDispatch = (m) => {
    if (!m) return;
    setSeeModal(true);
    setManufacturer(m);
    if (m?.manufacturer?.id) {
      getSeeAllDispatchList(m?.manufacturer?.id);
    }
  };

  const handleSeeClose = () => {
    setSeeModal(false);
  };

  const getSeeAllDispatchList = (id) => {
    setSpin(true);
    ApiCalling.apiCallGet(`dispatch-material/get/${id}`)
      .then((res) => {
        setDispatchedItem(res?.data);
      })
      .catch((err) => console.log("ERR", err))
      .finally(() => {
        setSpin(false);
      });
  };

  function isCloseModal() {
    setOpenModal(false);
    setManufacturer({});
  }

  const headerRender = ({ value, onChange }) => {
    const current = value.clone();
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "2px",
        }}
      >
        <button onClick={() => onChange(current.subtract(1, "month"))}>
          <BiChevronLeft />
        </button>
        <span className="text-[10px]">
          {moment(current).format("MMM Do YY")}
        </span>
        <button onClick={() => onChange(current.add(1, "month"))}>
          <BiChevronRight />
        </button>
      </div>
    );
  };

  const calculateRemainingDispatch = (totalDispatched, usedQuantity, unit) => {
    if (totalDispatched === "-") {
      return "-";
    }
    const usedQty = usedQuantity === "-" ? 0 : usedQuantity;
    const remainingQuantity = totalDispatched - usedQty;
    return `${remainingQuantity} ${unit}`;
  };

  return (
    <Main>
      <div className="flex items-start justify-between">
        <div className="flex flex-col gap-0">
          <h1 className="text-extralarge font-bold">Order Management</h1>
          <BreadcrumbComponent items={breadcrumbItems} />
        </div>
      </div>

      <Card className="mt-[30px] ">
        {loading ? (
          <div className="flex justify-center items-center">
            <Spin />
          </div>
        ) : Object.keys(flattenedData).length > 0 ? (
          flattenedData?.map((manufacturer) => (
            <div
              key={manufacturer?._id}
              className="  bg-input border-stroke-light p-2 mb-2 rounded items-start "
            >
              <h4 className="text-large mb-[10px]   mt-[10px]  px-2   font-semibold ">
                {manufacturer?.manufacturer?.name}
              </h4>
              <div className="flex gap-[20px]  ">
                <div className="px-2">
                  <Calendar
                    style={{
                      fontSize: "10px",
                      padding: 0,
                    }}
                    headerRender={headerRender}
                    className="w-[200px] small-chevron"
                    fullscreen={false}
                    rootClassName=""
                    dateFullCellRender={(date) => {
                      const dates = manufacturer?.dates || [];
                      const currentDate = date.format("YYYY-MM-DD");
                      const isWithinRange = dates.some(
                        ({ startDate, endDate }) => {
                          const isStartDate = currentDate === startDate;
                          const isEndDate = currentDate === endDate;
                          const isInRange = moment(currentDate).isBetween(
                            moment(startDate).startOf("day"),
                            moment(endDate).endOf("day"),
                            null,
                            "[]"
                          );
                          return isStartDate || isEndDate || isInRange;
                        }
                      );
                      const cellClass = isWithinRange
                        ? "text-white bg-blue-400"
                        : "text-black";
                      return (
                        <div
                          className={`custom-calendar mb-0 pb-0 ${cellClass}`}
                          style={{
                            borderRadius: "3px",
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            padding: 0,
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "6px",
                          }}
                        >
                          {date.date()}
                        </div>
                      );
                    }}
                  />
                </div>

                <div className="">
                  <table className="table-auto rounded  w-full overflow-hidden bg-white">
                    <thead className="h-[45px]  ">
                      <tr className="font-semiBold">
                        <th className=" font-medium border-slate-300 px-4 py-2 text-left text-nowrap text-stroke  text-[14px]">
                          Title
                        </th>
                        <th className=" font-medium border-slate-300 px-4 py-2 text-left text-nowrap text-stroke  text-[14px]">
                          Total needed
                        </th>
                        <th className=" font-medium border-slate-300 px-4 py-2 text-left text-nowrap text-stroke  text-[14px]">
                          Total dispatch
                        </th>

                        <th className=" font-medium border-slate-300 px-4 py-2 text-left text-nowrap text-stroke  text-[14px]">
                          Total used
                        </th>

                        <th className=" font-medium border-slate-300 px-4 py-2 text-left text-nowrap text-stroke  text-[14px]">
                          Remaining dispatch
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(manufacturer?.rm)?.map((key) => {
                        const rm = manufacturer?.rm[key];
                        return (
                          <tr
                            key={key}
                            className="border-t-[1px] text-[14px] border-stroke-light hover:bg-slate-50 bg-white rounded overflow-hidden"
                          >
                            <td className="text-nero px-4 py-[10px]   text-nowrap ">
                              {rm?.data?.title}
                            </td>
                            <td className=" px-4 py-[10px] text-nero  text-nowrap ">
                              {rm?.total} {rm?.total ? rm?.data?.unit : "-"}
                            </td>
                            <td className=" px-4 py-[10px] text-nero  text-nowrap  ">
                              {`${rm?.totalDispatched} ${
                                rm?.totalDispatched !== "-"
                                  ? rm?.data?.unit
                                  : ""
                              }`}
                            </td>

                            <td className=" px-4 py-[10px] text-nero  text-nowrap  ">
                              {`${rm?.usedQuantity} ${
                                rm?.usedQuantity !== "-" ? rm?.data?.unit : ""
                              }`}
                            </td>
                            <td className=" px-4 py-[10px] text-nero text-nowrap  ">
                              {calculateRemainingDispatch(
                                rm?.totalDispatched,
                                rm?.usedQuantity,
                                rm?.data?.unit
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="flex gap-[10px] justify-end mt-[10px]">
                    <Button
                      className="h-[35px]"
                      onClick={() => handleallSeeDispatch(manufacturer)}
                    >
                      See all dispatch
                    </Button>
                    <Button
                      type="primary"
                      className="h-[35px]"
                      icon={<BiPlus />}
                      onClick={() => handleDispatch(manufacturer)}
                    >
                      Dispatch
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <Empty description="No data available now!" />
        )}
      </Card>
      <Dispatch
        order={order}
        manufacturer={manufacturer}
        isModalOpen={openModal}
        isCloseModal={isCloseModal}
      />
      <ModalProvider
        isModalOpen={seeModal}
        OnClose={handleSeeClose}
        height={windowHeight - 100}
        loading={spin}
      >
        {dispatchedItem?.length > 0 ? (
          dispatchedItem?.map((rm) => {
            return (
              <>
                {rm?.type == "MaterialUsed" ? (
                  <DispatchUpdates data={rm} />
                ) : (
                  <div className="p-3 bg-input  border-1 rounded  mb-1 mt-[30px]">
                    <p className="text-gray-800 text-sm font-medium mb-2 rounded px-2  flex items-center gap-[5px]">
                      {`Dispatched on  ${Utils.formateDateForRead(
                        rm?.dispatchDate
                      )}`}
                    </p>
                    <table className="table-auto w-full border-collapse rounded overflow-hidden   bg-white">
                      <thead className=" ">
                        <tr>
                          <th className="border-b border-stroke-light px-4  text-stroke  text-left font-semibold">
                            Title
                          </th>
                          <th className="border-b border-stroke-light px-4  text-stroke  text-left font-semibold">
                            Quantity
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {rm?.dispatchItems?.map((item, index) => (
                          <tr
                            key={index}
                            className="text-sm text-nero hover:bg-gray-50 transition-all"
                          >
                            <td className="border-b  text-nero border-stroke-light px-4  ">
                              {item?.material?.title}
                            </td>
                            <td className="border-b  text-nero border-stroke-light px-4  ">
                              {item?.quantity} {item?.material?.unit}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </>
            );
          })
        ) : (
          <div className="flex justify-center w-full p-6 bg-gray-50 rounded-md shadow-md mt-[30px]">
            <Empty description="No dispatch material data." className="" />
          </div>
        )}
      </ModalProvider>
    </Main>
  );
};

export default OrderPlan;
