import React, { useEffect, useState } from "react";
import Main from "../../components/common/Main";
import { Button, Card, Col, Form, Input, message, Row } from "antd";
import BreadcrumbComponent from "../../components/breadcrumb/BreadcrumbComponent ";
import { Formik } from "formik";
import * as Yup from "yup";

import ApiCalling from "../../network/ApiCalling";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Utils from "../../utilites/Utils";

const CreateManufaturer = () => {
  const { state } = useLocation();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [vendorData, setVendorData] = useState(null);
  const breadcrumbItems = [
    { label: "Manufacturer", path: "/manufacturer" },
    { label: state?._id ? "Edit Manufacturer" : "Create Manufacturer" },
  ];

  useEffect(() => {
    if (state) {
      setVendorData(state);
    }
  }, [state]);

  const handleFormSubmit = (values, resetForm) => {
    setLoading(true);
    const apiCall = state
      ? ApiCalling.apiCallPut(`manufacturer/update`, {
          ...values,
          id: state?._id,
        })
      : ApiCalling.apiCallPost(`manufacturer/create`, values);

    apiCall
      .then((res) => {
        // console.log("RES", res);

        if (Utils.checkAPISuccessStatus(res)) {
          message.success(res.data.message || "Operation successful");
          resetForm();
          setTimeout(() => {
            navigate("/manufacturer");
          }, 1000);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Main>
      <div className="flex items-start justify-between">
        <div className="flex flex-col gap-0">
          <h1 className="text-extralarge font-bold">
            {state ? "Edit Manufacturer" : "Create Manufacturer"}
          </h1>
          <BreadcrumbComponent items={breadcrumbItems} />
        </div>
      </div>

      <Card className="mt-[30px]">
        <Formik
          enableReinitialize
          initialValues={
            vendorData || {
              name: "",
              machine: "",
              contactPerson: "",
              address: "",
              mobile: "",
              password: "",
            }
          }
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Name is required"),
            machine: Yup.string().required("machine is required"),
            contactPerson: Yup.string().required("Contact Person is required"),
            address: Yup.string().required("Address is required"),
            mobile: Yup.string().max(10).required("Mobile is required"),
            password: Yup.string().max(255).required("Password is required"),
          })}
          onSubmit={(values, { resetForm }) =>
            handleFormSubmit(values, resetForm)
          }
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Row gutter={40} className="p-[30px]">
                <Col span={12}>
                  <Form.Item
                    layout="vertical"
                    className="text-nero text-2xl"
                    label="Name"
                  >
                    <Input
                      name="name"
                      value={values?.name || ""}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.name && errors.name && (
                      <Form.Item className="text-red-500  ">
                        {errors.name}
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    layout="vertical"
                    className="text-nero text-2xl"
                    label="Total Machine"
                  >
                    <Input
                      name="machine"
                      value={values.machine}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.machine && errors.machine && (
                      <Form.Item className="text-red-500  ">
                        {errors.machine}
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    layout="vertical"
                    className="text-nero text-2xl"
                    label="Contact Person"
                  >
                    <Input
                      name="contactPerson"
                      value={values.contactPerson || ""}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.contactPerson && errors.contactPerson && (
                      <Form.Item className="text-red-500  ">
                        {errors.contactPerson}
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    layout="vertical"
                    className="text-nero text-2xl"
                    label="Mobile"
                  >
                    <Input
                      name="mobile"
                      value={values.mobile || ""}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.mobile && errors.mobile && (
                      <Form.Item className="text-red-500  ">
                        {errors.mobile}
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    layout="vertical"
                    className="text-nero text-2xl"
                    label="Address"
                  >
                    <Input.TextArea
                      name="address"
                      rows={4}
                      value={values.address || ""}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.address && errors.address && (
                      <Form.Item
                        className="text-red-500 text-sm h-4"
                        style={{
                          color: "red",
                          fontSize: "12px",
                          height: "20px",
                        }}
                      >
                        {errors.address}
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    layout="vertical"
                    className="text-nero text-2xl"
                    label="Password"
                  >
                    <Input
                      name="password"
                      value={values.password || ""}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.password && errors.password && (
                      <Form.Item className="text-red-500  ">
                        {errors.password}
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} className="pl-[30px]  justify-end">
                <Col>
                  <Button
                    onClick={() => navigate(-1)}
                    type=""
                    htmlType="button"
                    className="rounded h-[40px] font-sans  "
                  >
                    Cancel
                  </Button>
                </Col>
                <Col className=" ">
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="rounded h-[40px] font-sans  "
                  >
                    {state?._id ? "Save Changes" : "Save"}
                  </Button>
                </Col>
              </Row>
            </form>
          )}
        </Formik>
      </Card>
    </Main>
  );
};

export default CreateManufaturer;
